<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">

          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("couriers.new courier") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.courier information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="courierData.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.email')"
                      placeholder="abc@example.com"
                      input-classes="form-control-alternative"
                      v-model="courierData.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="courierData.phone"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.shipping type")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="courierData.shipping_type"
                    >
                      <option value="" selected>
                        {{ $t("form.choose shipping type") }}
                      </option>
                      <option
                        v-for="(shippingType, index) in shippingTypes"
                        :key="index"
                        :value="shippingType.id"
                      >
                        {{ shippingType.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="!this.$route.params.id">
                <hr class="my-4" />
                <!-- Information -->
                <h6 class="heading-small text-muted mb-4">
                  {{ $t("form.additional information") }}
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{
                          $t("couriers.license image")
                        }}</label>
                        <input
                          type="file"
                          class="form-control form-control-alternative"
                          v-on:change="uploadLicense"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{
                          $t("couriers.plate image")
                        }}</label>
                        <input
                          type="file"
                          class="form-control form-control-alternative"
                          v-on:change="uploadPlate"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{
                          $t("couriers.national id image")
                        }}</label>
                        <input
                          type="file"
                          class="form-control form-control-alternative"
                          v-on:change="uploadNationalId"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{
                          $t("couriers.personal image")
                        }}</label>
                        <input
                          type="file"
                          class="form-control form-control-alternative"
                          v-on:change="uploadAvatar"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        type="password"
                        alternative=""
                        :label="$t('form.password')"
                        :placeholder="$t('form.password')"
                        input-classes="form-control-alternative"
                        v-model="courierData.password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="col-lg-12 mt-4">
                <button type="submit" class="btn btn-success d-block">
                  {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
                </button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "new-courier",
  data() {
    return {
      loading: false,
      validationErrors: [],

      shippingTypes: "",
      courierData: {
        name: "",
        email: "",
        phone: "",
        shipping_type: "",
        password: "",
        online: "0",
        enable: "0",
        license_image: "",
        plate_image: "",
        national_id: "",
        avatar: "",
      },
    };
  },
  created() {
    this.getShippingTypes();
    if (this.$route.params.id) {
      this.getCourier(this.$route.params.id);
    }
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.courierData.name &&
        this.courierData.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.courierData.email
        ) &&
        this.courierData.phone &&
        this.courierData.shipping_type &&
        // eslint-disable-next-line prettier/prettier
        (this.courierData.password || this.$route.params.id > 0) &&
        // eslint-disable-next-line prettier/prettier
        (this.courierData.license_image || this.$route.params.id > 0) &&
        // eslint-disable-next-line prettier/prettier
        (this.courierData.plate_image || this.$route.params.id > 0) &&
        // eslint-disable-next-line prettier/prettier
        (this.courierData.national_id || this.$route.params.id > 0) &&
        // eslint-disable-next-line prettier/prettier
        (this.courierData.avatar || this.$route.params.id > 0)
      ) {
        return true;
      } else {
        if (!this.courierData.name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.courierData.email) {
          this.validationErrors.push(`${this.$t("validation.email required")}`);
        }
        if (
          this.courierData.email &&
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.courierData.email
          )
        ) {
          this.validationErrors.push(
            `${this.$t("validation.email not valid")}`
          );
        }
        if (!this.courierData.phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.courierData.shipping_type) {
          this.validationErrors.push(
            `${this.$t("validation.shipping type required")}`
          );
        }
        if (!this.courierData.license_image && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.license image required")}`
          );
        }
        if (!this.courierData.plate_image && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.plate image required")}`
          );
        }
        if (!this.courierData.national_id && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.id image required")}`
          );
        }
        if (!this.courierData.avatar && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.personal image required")}`
          );
        }
        if (!this.courierData.password && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.password required")}`
          );
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        this.courierData._method = "put";
        let formData = this.toFormData(this.courierData);
        this.updateCourier(formData);
      } else {
        let formData = this.toFormData(this.courierData);
        this.addCourier(formData);
      }
    },
    // Get Shipping Types
    getShippingTypes: function () {
      this.loading = true;
      axios
        .get("trader/common/shippingTypes")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.shippingTypes = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Courier Information
    getCourier: function () {
      this.loading = true;
      axios
        .get("trader/traders/couriers/" + this.$route.params.id)
        .then((response) => {
          if (!response.data.error) {
            this.courierData = {};
            this.courierData.id = response.data.data[0].id;
            this.courierData.name = response.data.data[0].name;
            this.courierData.email = response.data.data[0].email;
            this.courierData.phone = response.data.data[0].phone;
            this.courierData.shipping_type =
              response.data.data[0].shipping_types_id;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Add Courier
    addCourier: function (formData) {
      this.loading = true;
      axios
        .post("trader/traders/couriers/register", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/couriers");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Courier
    updateCourier: function (formData) {
      this.loading = true;
      axios
        .post("trader/traders/couriers/update", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/couriers");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Image to Add
    uploadLicense(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.courierData.license_image = files[0];
    },
    uploadPlate(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.courierData.plate_image = files[0];
    },
    uploadNationalId(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.courierData.national_id = files[0];
    },
    uploadAvatar(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.courierData.avatar = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
