<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <select
            class="btn btn-primary btn-sm text-left"
            @change="
              getFilterdOrders('trader/traders/orders/search?page=1', $event)
            "
          >
            <option value="">{{ $t("orders.filter by status") }}</option>
            <option value="all">{{ $t("tables.all") }}</option>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
          <router-link :to="`/order-form`" class="btn btn-primary btn-sm"
            ><i class="fas fa-plus"></i> {{ $t("sidebar.create auction") }}</router-link
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="orders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.price") }}</th>
          <th>{{ $t("tables.address") }}</th>
          <th>{{ $t("tables.type") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <span class="name mb-0 text-sm">{{ row.item.receiver_name }}</span>
          </th>
          <td class="budget">
            {{ row.item.receiver_phone }}
          </td>
          <td class="budget">
            {{ row.item.price }}
          </td>
          <td class="budget">
            {{ row.item.address }}
          </td>
          <td class="budget">
            {{ row.item.order_type }}
          </td>
          <td class="budget">
            <span v-if="row.item.statuses.length > 0">
              {{ row.item.statuses[row.item.statuses.length - 1].name }}
            </span>
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/orders/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/orders/track/${row.item.id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-map-marker-alt"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      orders: "",
      page: 1,
      total_pages: "",
      statuses: "",
      current_status: "",
    };
  },
  created() {
    this.getOrders("trader/traders/orders/index?page=1");
    this.getStatus();
    this.moment = moment;
  },
  watch: {
    page: function () {
      if (this.current_status == "" || this.current_status == "all") {
        this.getOrders("trader/traders/orders/index?page=" + this.page);
      } else {
        this.getFilterdOrders("trader/traders/orders/search?page=" + this.page);
      }
    },
  },
  methods: {
    getOrders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.orders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get statues to filter
    getStatus: function () {
      this.loading = true;
      axios
        .get("trader/traders/orders/statuses")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.statuses = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Fiter Orders
    getFilterdOrders: function ($pagination) {
      if (event.target.value) {
        this.current_status = event.target.value;
      }
      // If choose 'all' in Filterd Box
      if (this.current_status == "all") {
        this.getOrders("trader/traders/orders/index?page=1");
      } else {
        this.loading = true;
        axios
          .post($pagination, {
            status: this.current_status,
          })
          .then((response) => {
            if (response.data.error) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
              });
            } else {
              this.orders = response.data.data;
              this.page = response.data.meta.current_page;
              this.total_pages = response.data.meta.last_page;
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: `${this.$t("validation.check network")}`,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
