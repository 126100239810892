<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <items-table type="dark" :title="$t('items.items')"></items-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemsTable from "../Tables/Items/ItemsTable.vue";
export default {
  name: "tables",
  components: {
    ItemsTable,
  },
};
</script>
<style></style>
