<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="orders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.address") }}</th>
          <th>{{ $t("tables.customer type") }}</th>
          <th>{{ $t("global.payment method") }}</th>
          <th>{{ $t("tables.type") }}</th>
          <th>{{ $t("tables.delivery time") }}</th>
          <th>{{ $t("tables.items") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <span class="name mb-0 text-sm">{{ row.item.receiver_name }}</span>
          </th>
          <td class="budget">
            {{ row.item.receiver_phone }}
          </td>
          <td class="budget">
            {{ row.item.receiver_address }}
          </td>
          <td class="budget">
            {{ row.item.customer_type }}
          </td>
          <td class="budget">
            <span v-if="row.item.payment_methods_id == 1">
              {{ $t("form.cash") }}
            </span>
            <span v-else-if="row.item.payment_methods_id == 3">
              {{ $t("form.credit") }}
            </span>
          </td>
          <td class="budget">{{ row.item.order_type }}</td>
          <td class="budget">
            <span v-if="row.item.now == 1">
              {{ $t("form.now") }}
            </span>
            <span v-else>
              {{ row.item.delivery_date }}
            </span>
          </td>
          <td class="budget">
            <h5
              class="text-white"
              v-for="(item, index) in row.item.items"
              :key="index"
            >
              {{ item.item.title + " : " + item.quantity }}
            </h5>
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget text-right">
            <button
              @click="acceptOrder(row.item)"
              class="btn btn-success btn-sm"
            >
              <i class="far fa-check-square"></i> {{ $t("tables.accept") }}
            </button>
            <button
              @click="refuseOrder(row.item)"
              class="btn btn-danger btn-sm"
            >
              <i class="far fa-window-close"></i> {{ $t("tables.refuse") }}
            </button>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      orders: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getOrders("trader/orders/pending?page=1");
    this.moment = moment;
  },
  watch: {
    page: function () {
      this.getOrders("trader/orders/pending?page=" + this.page);
    },
  },
  methods: {
    getOrders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.orders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    acceptOrder: function (order) {
      this.loading = true;
      axios
        .post("trader/traders/orders/accept", { order_id: order.id })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            localStorage.setItem(
              "pending_orders",
              localStorage.getItem("pending_orders") - 1
            );
            this.$store.dispatch(
              "UpdatePendingOrders",
              localStorage.getItem("pending_orders")
            );
            this.$swal({
              position: "center",
              icon: "success",
              title: `${this.$t("validation.success")}`,
              text: response.data.message,
              showConfirmButton: false,
              timer: 1200,
            });
            setTimeout(() => {
              this.orders.splice(this.orders.indexOf(order), 1);
            }, 600);
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    refuseOrder: function (order) {
      this.loading = true;
      axios
        .post("trader/traders/orders/reject", { order_id: order.id })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            localStorage.setItem(
              "pending_orders",
              localStorage.getItem("pending_orders") - 1
            );
            this.$store.dispatch(
              "UpdatePendingOrders",
              localStorage.getItem("pending_orders")
            );
            this.$swal({
              position: "center",
              icon: "success",
              title: `${this.$t("validation.success")}`,
              text: response.data.message,
              showConfirmButton: false,
              timer: 1200,
            });
            setTimeout(() => {
              this.orders.splice(this.orders.indexOf(order), 1);
            }, 600);
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
