<template>
  <li
    class="text-center"
    v-if="category.id"
    @click="$emit('scroll-to-section', category.name)"
  >
    <img :src="category.image" />
    <span class="d-block">{{ category.name }}</span>
  </li>
</template>

<script>
export default {
  props: ["category"],
  emits: ["scroll-to-section"],
};
</script>
<style lang="scss" scoped>
.fixed_sec img{
  display: none;
}
ul {
  // overflow-x: auto;
  // display: flex;
  li {
    background-image: linear-gradient(to bottom, white 70%, #cccccc9e 84%);
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    display: inline-block;
    margin-right: 8px;
    padding: 5px 15px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }
}
</style>
