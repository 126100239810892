<template>
  <!-- Product Modal  -->
  <div
    class="modal"
    :class="{ show: showProductModal }"
    tabindex="-1"
    role="dialog"
    v-if="showProductModal"
  >
    <div class="modal-dialog" role="document">
      <!-- <div v-if="loading">dasasdads</div>
      <div v-else>d0000000</div> -->
      <div class="modal-content">
        <div class="modal-body p-0">
          <!-- <img src="../assets/coffee.jpeg" class="w-100" alt="" /> -->
          <div class="item-info p-3">
            <h4>{{ Item.title }}</h4>
            <h6>{{ Item.price }}</h6>
            <p class="text-dark mb-0">{{ Item.content }}</p>
          </div>
          <div class="item-qty p-3">
            <h4>Quantity</h4>
            <div class="d-flex justify-content-center">
              <span class="decrease" @click="decreaseQty">
                <i class="fas fa-minus"></i>
              </span>
              <span class="qty">{{ qty }}</span>
              <span class="increase" @click="increaseQty">
                <i class="fas fa-plus"></i>
              </span>
            </div>
          </div>
          <div class="p-2">
            <button class="d-block w-100 p-3" @click="addToCart()">
              Add {{ qty }} to Basket
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="
              showProductModal = false;
              qty = 1;
            "
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Cart Modal -->
  <div class="modal" id="cartModal" v-if="showCartModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Your cart</h5>
          <button
            type="button"
            class="btn-close"
            @click="showCartModal = false"
          ></button>
        </div>
        <div class="modal-body text-dark">
          <div
            class="cart-product mb-3 d-flex justify-content-between"
            v-for="(item, index) in cart"
            :key="index"
          >
            <div>
              <p class="mb-0">{{ item.product.local_title }}</p>
              <p>{{ item.product.price * item.quantity }}</p>
            </div>
            <div class="item-qty d-flex">
              <span
                class="decrease"
                @click="
                  item.quantity--;
                  updateCart(item.product, item.quantity);
                "
              >
                <i class="fas fa-minus"></i>
              </span>
              <span class="qty">{{ item.quantity }}</span>
              <span
                class="increase"
                @click="
                  item.quantity++;
                  updateCart(item.product, item.quantity);
                "
              >
                <i class="fas fa-plus"></i>
              </span>
            </div>
          </div>
          <hr class="my-5" />
          <div class="d-flex justify-content-between">
            <strong>Total</strong>
            <strong>{{ cartTotalPrice }}</strong>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="showCartModal = false"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="home">
    <section class="restaurant-info py-1 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <img :src="get_trader_logo" width="100px">
        <div
          class="d-flex align-items-center justify-content-end order-cart-info"
        >
          <p @click="showCartModal = true" class="cart-icon mb-0">
            <i class="fas fa-shopping-cart"></i> {{ cartItemCount }} cart
          </p>
        </div>

      </div>
    </section>
    <!-- Categories -->
    <section class="categories py-2 mb-2" :class="{ fixed_sec: fixedCatsBar }">
      <ul class="list-unstyled p-0 mb-0">
        <Category
          :category="category"
          v-for="(category, index) in Categories"
          :key="index"
          @scroll-to-section="scrollToSection"
        />
      </ul>
    </section>
    <!-- Items -->
    <section class="all-products">
      <div
        id="target-element"
        v-for="(cat, index) in Categories"
        :key="index"
        :ref="cat.name"
      >
        <div class="products-sec">
          <h3>{{ cat.name }}</h3>
          <div class="row">
            <div
              class="col-md-4"
              v-for="(item, index) in cat.items"
              :key="index"
            >
              <div
                class="product-box mb-5 d-flex justify-content-between align-items-center"
                @click="
                  showProductModal = true;
                  qty = 1;
                  getCurrentItem(item);
                "
              >
                <div class="w-100 position-relative">
                  <!-- <img :src="item.images[0]" /> -->
                  <img
                    src="https://nqal.co/testNqal/public/uploads/items/0164a59feff74a2307e0019acfe42a13b26b0bec.jpeg"
                  />
                  <div class="product-info">
                    <div
                      class="d-flex align-items-baseline justify-content-between"
                    >
                      <h4>{{ item.title }}</h4>
                      <span>AED {{ item.price }}</span>
                    </div>
                    <div
                      class="plus-icon d-flex align-items-center justify-content-center"
                    >
                      <i class="fas fa-plus"></i>
                    </div>
                    <!-- <p class="my-0">{{ item.content }}</p> -->
                  </div>
                </div>
                <!-- <img :src="require(`../assets/coffee.jpeg`)" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import Category from "./MenuCategory.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Category,
    // Item,
  },
  data() {
    return {
      showProductModal: false,
      showCartModal: false,
      qty: 1,
      scrollPosition: null,
      // targetSection: document.getElementById('all-products'),
      fixedCatsBar: false,
      tradeInfo: "",
      Categories: "",
      Item: "",
    };
  },
  methods: {
    // Get Categories
    getCategories: function () {
      // this.loading = true;
      axios
        .post("trader/common/trader/menu", { trader_name: "trader1" })
        .then((response) => {
          if (response.data.error) {
            // Error
          } else {
            if (response.data.data.length == "0") {
              // No Data
            } else {
              this.tradeInfo = response.data.data[0];
              this.Categories = response.data.data[0].subTraderType;
            }
          }
        })
        .catch(() => {
          //
        })
        .finally(() => (this.loading = false));
    },
    getCurrentItem: function (item) {
      this.Item = item;
    },
    // Cart Methods
    increaseQty() {
      this.qty++;
    },
    decreaseQty() {
      this.qty > 1 ? this.qty-- : "";
    },
    scrollToSection(refName) {
      var element = this.$refs[refName];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    isScrolledIntoView(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      this.isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      return this.isVisible;
    },
    handleScroll() {
      /* if scrolled into view */
      if (this.isScrolledIntoView(this.el)) this.el.classList.add("slide");
    },

    // Add Item To Cart
    addToCart() {
      this.$store.dispatch("addProductToCart", {
        product: this.Item,
        quantity: this.qty,
      });
      this.showProductModal = false;
    },
    // Update Cart From Cart Modal
    updateCart(item, qty) {
      this.$store.dispatch("addProductToCart", {
        product: item,
        quantity: qty,
        updated: 1,
      });
      this.showProductModal = false;
    },
  },
  mounted() {
    this.getCategories();
    let categories = $(".categories"),
      sticky = $(".all-products"),
      stickyTop = sticky.offset().top,
      scrolled = false,
      $window = $(window);
    /* Bind the scroll Event */
    $window.on("scroll", function () {
      scrolled = true;
    });
    setInterval(function () {
      if (scrolled) {
        scrolled = false;
        if ($window.scrollTop() >= stickyTop) {
          this.fixedCatsBar = true;
          categories.addClass("fixed_sec");
        } else {
          this.fixedCatsBar = false;
          categories.removeClass("fixed_sec");
        }
      }
    }, 200);
  },
  computed: {
    get_trader_logo() {
      return JSON.parse(localStorage.getItem("nqalTraderLoginData"))
        .trader_logo;
    },
    loading() {
      return this.$store.getters.isLoading;
    },
    cart() {
      return this.$store.getters.cart;
    },
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
    cartTotalPrice() {
      return this.$store.getters.cartTotalPrice;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  display: unset;

  .modal-dialog {
    width: 700px;
    max-width: 85%;
  }
}
#cartModal,
.modal.show {
  background: rgba(0, 0, 0, 0.4);
}
.modal .close {
  border: 0;
}
.modal img {
  height: 220px;
  object-fit: cover;
}
.modal .item-info {
  border-bottom: 2px solid #222;
}
.modal .item-info h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
}
.modal .item-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.modal button {
  border: 0;
}
.item-qty span {
  font-size: 25px;
  margin: 0 8px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  i {
    font-size: 20px;
  }
}
.item-qty span:hover {
  background: #222;
}
header img {
  height: 350px;
  object-fit: cover;
}
.restaurant-info {
  .restaurant-address {
    font-weight: 500;
  }
  .cart-order-info{
    background: #65d1d1;
    color: #fff;
    padding: 4px 8px;
    border-radius: 8px;
    .cart-icon {
      cursor: pointer;
    }
  }
}
.categories.fixed_sec {
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: #fff;
  li{
    background-image: unset;
    background: #65d1d1;
  }
}
.fixed_sec img{
  display: none !important;
}
.categories ul{
  overflow-x: auto;
  white-space: nowrap;
}

.products-sec {
  background: #f0f8ff70;
  box-shadow: 0 3px 4px #ccc;
  padding: 25px 15px;
  margin-bottom: 18px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  h3 {
    color: #222;
    border-bottom: 1px solid #222;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .product-box {
    background-color: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    .plus-icon {
      /* position: absolute; */
      /* inset-inline-end: 6px; */
      /* top: 6px; */
      background: #65d1d1;
      color: #fff;
      width: 80px;
      height: 30px;
      /* color: #222; */
      border-radius: 6px;
      margin-inline-start: auto;
    }
    img {
      width: 100%;
      height: 140px;
      object-fit: contain;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
    .product-info {
      padding: 10px;
      h4 {
        font-size: 14px;
        font-weight: 700;
        color: #222;
      }
      p {
        color: #222;
      }
      span {
        white-space: nowrap;
        font-weight: 700;
        font-size: 15px;
        color: #333;
        margin-inline-start: 5px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .product-box img {
    width: 120px;
    height: 120px;
  }
  .product-box h4 {
    font-size: 15px;
  }
  .product-box p {
    font-size: 14px;
    line-height: 1.3;
  }
}
</style>
