<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ $t("sidebar.customers") }}
        </h3>
        <div>
          <form @submit.prevent="searchCustomers">
            <input
              v-model="searchVal"
              type="text"
              name=""
              id=""
              class="form-control"
              :placeholder="$t('tables.search by name phone')"
            />
          </form>
        </div>
      </div>
    </div>

    <div class="alert alert-danger" v-if="noData">
      {{ $t("tables.no data") }}
    </div>
    <div class="table-responsive" v-else>
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="customers"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img :src="row.item.photo" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget ltr">
            {{ row.item.countryCode + "-" + row.item.phone }}
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <!-- <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      /> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "customers-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      noData: false,
      searchVal: "",
      customers: [],
      // page: 1,
      // total_pages: "",
    };
  },
  // watch: {
  //   page: function () {
  //     this.getCouriers("trader/subTraderTypes/index?page=" + this.page);
  //   },
  // },
  methods: {
    searchCustomers: function () {
      this.loading = true;
      axios
        .post("appsSettings/common/searchCustomer", { key: this.searchVal })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.data.length > 0) {
              this.noData = false;
              this.customers = response.data.data;
            } else {
              this.noData = true;
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.ltr{
  direction: ltr;
}
</style>