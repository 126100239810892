<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsg">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsg"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card v-else shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.generate courier report") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("reports.date information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-control-label">
                      {{ $t("form.choose courier") }}
                    </label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="model.courier"
                    >
                      <option
                        v-for="(courier, index) in couriers"
                        :key="index"
                        :value="courier.id"
                      >
                        {{ courier.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :label="$t('global.from')"
                      :placeholder="$t('global.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.from_date"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :label="$t('global.to')"
                      :placeholder="$t('global.to')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.to_date"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('reports.generate report')"
                @click.prevent="sendDatestoGenerate"
              />
            </form>
          </card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <couriers-total-table
            type="dark"
            :title="$t('tables.total report')"
          ></couriers-total-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CouriersTotalTable from "../../Tables/Reports/Couriers/CouriersTotalTable.vue";

export default {
  name: "Generate-Couriers-Report",
  components: {
    CouriersTotalTable,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      couriers: "",
      model: {
        from_date: "",
        to_date: "",
        courier: "",
      },
    };
  },
  created() {
    this.getCouriers();
  },
  methods: {
    // Get All Couriers
    getCouriers: function () {
      this.loading = true;
      axios
        .get("trader/traders/couriers/index")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.couriers = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Send Date to get Report
    sendDatestoGenerate: function () {
      if (
        this.model.courier &&
        this.model.from_date.length > 0 &&
        this.model.to_date.length > 0
      ) {
        this.$router.push({
          name: "get couriers report",
          params: {
            dates: [
              this.model.from_date,
              this.model.to_date,
              this.model.courier,
            ],
          },
        });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          text: `${this.$t("validation.all fields required")}`,
        });
      }
    },
  },
};
</script>
