<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("items.new item") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("items.item information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="file"
                      alternative=""
                      :label="$t('form.image')"
                      :placeholder="$t('form.image')"
                      input-classes="form-control-alternative"
                      v-on:change="onFileChange"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.ar name')"
                      :placeholder="$t('form.ar name')"
                      input-classes="form-control-alternative"
                      v-model="itemData.ar_title"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.en name')"
                      :placeholder="$t('form.en name')"
                      input-classes="form-control-alternative"
                      v-model="itemData.en_title"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.ar description")
                      }}</label>
                      <textarea
                        class="form-control form-control-alternative"
                        :placeholder="$t('form.ar description')"
                        v-model="itemData.ar_content"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.en description")
                      }}</label>
                      <textarea
                        class="form-control form-control-alternative"
                        :placeholder="$t('form.en description')"
                        v-model="itemData.en_content"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      type="text"
                      alternative=""
                      :label="$t('form.price')"
                      :placeholder="$t('form.price')"
                      input-classes="form-control-alternative"
                      v-model="itemData.price"
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label d-block">
                        {{ $t("form.is offered") }}
                      </label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="is_offered"
                          true-value="1"
                          false-value="0"
                          v-model="itemData.is_offered"
                        />
                        <label class="form-check-label" for="is_offered">
                          {{ $t("form.is offered") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="itemData.is_offered == 1">
                    <base-input
                      alternative=""
                      :label="$t('form.offered price')"
                      :placeholder="$t('form.offered price')"
                      input-classes="form-control-alternative"
                      v-model="itemData.offered_price"
                    />
                  </div>
                  <div class="col-md-6">
                    <base-input
                      type="text"
                      alternative=""
                      :label="$t('form.qty')"
                      :placeholder="$t('form.qty')"
                      input-classes="form-control-alternative"
                      v-model="itemData.quantity"
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label d-block">
                        {{ $t("form.free delivery") }}
                      </label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="is_freeDelivery"
                          true-value="1"
                          false-value="0"
                          v-model="itemData.is_freeDelivery"
                        />
                        <label class="form-check-label" for="is_freeDelivery">
                          {{ $t("form.free delivery") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-select form-control form-control-alternative"
                        v-model="itemData.sub_trader_type_id"
                      >
                        <option value="" selected="selected">
                          {{ $t("form.type") }}
                        </option>
                        <option
                          v-for="(subType, index) in supTraderTypes"
                          :key="index"
                          :value="subType.id"
                        >
                          {{ subType.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.pay type")
                      }}</label>
                      <select
                        class="form-select form-control form-control-alternative"
                        v-model="itemData.pay_type"
                      >
                        <option value="" selected="selected">
                          {{ $t("form.pay type") }}
                        </option>
                        <option value="cash">
                          {{ $t("form.cash") }}
                        </option>
                        <option value="credit">
                          {{ $t("form.credit") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <button type="submit" class="btn btn-success d-block">
                {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "new-item",
  data() {
    return {
      loading: false,
      validationErrors: [],
      itemData: {
        imgs: [],
        ar_title: "",
        en_title: "",
        ar_content: "",
        en_content: "",
        price: "",
        quantity: "",
        is_freeDelivery: 0,
        pay_type: "",
        is_offered: 0,
        offered_price: 0,
        sub_trader_type_id: "",
      },
      supTraderTypes: "",
    };
  },
  created() {
    this.getsupTraderTypes();
    if (this.$route.params.id) {
      this.getItem(this.$route.params.id);
    }
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        (this.itemData.imgs || this.$route.params.id > 0) &&
        this.itemData.ar_title &&
        this.itemData.en_title &&
        this.itemData.ar_content &&
        this.itemData.en_content &&
        this.itemData.price &&
        this.itemData.quantity &&
        this.itemData.sub_trader_type_id
      ) {
        return true;
      } else {
        if (this.itemData.imgs.length < 1 || this.$route.params.id > 0) {
          this.validationErrors.push(`${this.$t("validation.img required")}`);
        }
        if (!this.itemData.ar_title) {
          this.validationErrors.push(
            `${this.$t("validation.ar name required")}`
          );
        }
        if (!this.itemData.en_title) {
          this.validationErrors.push(
            `${this.$t("validation.en name required")}`
          );
        }
        if (!this.itemData.ar_content) {
          this.validationErrors.push(
            `${this.$t("validation.ar desc required")}`
          );
        }
        if (!this.itemData.en_content) {
          this.validationErrors.push(
            `${this.$t("validation.en desc required")}`
          );
        }
        if (!this.itemData.price) {
          this.validationErrors.push(`${this.$t("validation.price required")}`);
        }
        if (!this.itemData.quantity) {
          this.validationErrors.push(`${this.$t("validation.qty required")}`);
        }
        if (!this.itemData.sub_trader_type_id) {
          this.validationErrors.push(`${this.$t("validation.type required")}`);
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        this.itemData._method = "put";
        let formData = this.toFormData(this.itemData);
        this.updateItem(formData);
      } else {
        let formData = this.toFormData(this.itemData);
        this.addItem(formData);
      }
    },
    // Add Item
    addItem: function (formData) {
      this.loading = true;
      axios
        .post("trader/items/createItem", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/items");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Item
    getItem: function () {
      this.loading = true;
      axios
        .get("trader/items/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.itemData = {};
            this.itemData.ar_title = response.data.data[0].local_title;
            this.itemData.en_title = response.data.data[0].title;
            this.itemData.ar_content = response.data.data[0].local_content;
            this.itemData.en_content = response.data.data[0].content;
            this.itemData.price = response.data.data[0].price;
            this.itemData.quantity = response.data.data[0].quantity;
            this.itemData.is_freeDelivery =
              response.data.data[0].is_freeDelivery;
            this.itemData.pay_type = response.data.data[0].pay_type;
            this.itemData.is_offered = response.data.data[0].is_offered;
            this.itemData.offered_price = response.data.data[0].offered_price;
            this.itemData.sub_trader_type_id =
              response.data.data[0].sub_trader_type_id;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Edit Item
    updateItem: function (formData) {
      this.loading = true;
      axios
        .post("trader/items/updateItem/" + this.$route.params.id, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/items");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Sub Trader Types
    getsupTraderTypes: function () {
      this.loading = true;
      axios
        .post("appsSettings/common/subTraderTypesByTrader", {
          trader_id: this.$store.getters.traderId,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.supTraderTypes = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.itemData.imgs = files[0];
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.$route.params.id) {
        fd.append("_method", "put");
      }
      if (this.itemData["imgs"]) {
        fd.append("images[]", this.itemData.imgs);
      }
      fd.append("category_id", 1);
      fd.append("ar[title]", this.itemData["ar_title"]);
      fd.append("en[title]", this.itemData["en_title"]);
      fd.append("ar[content]", this.itemData["ar_content"]);
      fd.append("en[content]", this.itemData["en_content"]);
      fd.append("price", this.itemData["price"]);
      fd.append("quantity", this.itemData["quantity"]);
      fd.append("is_offered", this.itemData["is_offered"]);
      fd.append("offered_price", this.itemData["offered_price"]);
      fd.append("pay_type", this.itemData["pay_type"]);
      fd.append("is_freeDelivery", this.itemData["is_freeDelivery"]);
      fd.append("sub_trader_type_id", this.itemData["sub_trader_type_id"]);
      return fd;
    },
  },
};
</script>
