import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app";
const firebaseApp = initializeApp({
  // const firebaseConfig = {
  apiKey: "AIzaSyAZqWPTvoyk-KIOWkY1CNXFTE0P8J-Ahpo",
  authDomain: "nqal-e1f7f.firebaseapp.com",
  databaseURL: "https://nqal-e1f7f-default-rtdb.firebaseio.com",
  projectId: "nqal-e1f7f",
  storageBucket: "nqal-e1f7f.appspot.com",
  messagingSenderId: "882165725972",
  appId: "1:882165725972:web:df7481deb54931e0952001",
  measurementId: "G-HN3VZP58P8",
});

const db = getFirestore(firebaseApp);
const messaging = getMessaging();

// SweetAlert
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

onMessage(messaging, (payload) => {
  new Swal({
    position: "top-end",
    title: payload.notification.title,
    text: payload.notification.body,
    showConfirmButton: false,
    timer: 4000,
  });
});

export { firebaseApp, db, collection, getDocs, messaging, getToken };
