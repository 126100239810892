<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("deals.new deal") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("deals.deal information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('tables.subject')"
                      :placeholder="$t('tables.subject')"
                      input-classes="form-control-alternative"
                      v-model="dealData.subject"
                    />
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("tables.message")
                      }}</label>
                      <textarea
                        class="form-select form-control form-control-alternative"
                        :placeholder="$t('tables.message')"
                        v-model="dealData.message"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <button type="submit" class="btn btn-success d-block">
                {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "new-deal",
  data() {
    return {
      loading: false,
      validationErrors: [],
      dealData: {
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (this.dealData.subject && this.dealData.message) {
        return true;
      } else {
        if (!this.dealData.subject) {
          this.validationErrors.push(
            `${this.$t("validation.subject required")}`
          );
        }
        if (!this.dealData.message) {
          this.validationErrors.push(
            `${this.$t("validation.message required")}`
          );
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        // this.itemData._method = "put";
        // let formData = this.toFormData(this.itemData);
        // this.updateItem(formData);
      } else {
        this.requestDeal(this.dealData);
      }
    },
    // Request New Deal
    requestDeal: function (formData) {
      this.loading = true;
      axios
        .post("trader/TraderDealCompanyRequests/store", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.msg);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.msg,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/deals");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
