<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      :logo="get_trader_logo"
      short-title="Nqal"
      title="Nqal"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'ni ni-chart-pie-35 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.all users'),
            icon: 'ni fas fa-users text-primary',
            path: '/users',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.new user'),
            icon: 'ni fas fa-user-plus text-primary',
            path: '/new-user',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.roles'),
            icon: 'ni fas fa-user-tag text-primary',
            path: '/roles',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.add role'),
            icon: 'ni fas fa-user-shield text-primary',
            path: '/add-role',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.customers'),
            icon: 'ni fas fa-users text-primary',
            path: '/customers',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.deals'),
            icon: 'ni fas fa-handshake text-primary',
            path: '/deals',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.companies'),
            icon: 'ni fas fa-building text-primary',
            path: '/companies',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.categories'),
            icon: 'ni fas fa-boxes text-primary',
            path: '/categories',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.items'),
            icon: 'ni fas fa-box text-primary',
            path: '/items',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.menu settings'),
            icon: 'ni ni-settings-gear-65 text-primary',
            path: '/settings/menu',
          }"
        />
        <!-- Reports -->
        <sidebar-item
          :link="{
            name: $t('sidebar.generate trader report'),
            icon: 'ni fas fa-file-alt text-primary',
            path: '/generate-traders-report',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.update profile'),
            icon: 'ni ni-single-02 text-primary',
            path: '/settings/update-profile',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.update working hours'),
            icon: 'ni ni-calendar-grid-58 text-primary',
            path: '/settings/working-hours',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.wallet'),
            icon: 'ni fas fa-wallet text-primary',
            path: '/wallet',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers'),
            icon: 'ni ni-delivery-fast text-primary',
            path: '/couriers',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.general_orders'),
            icon: 'ni ni-cart text-primary',
            path: '/general-orders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.menu_orders'),
            icon: 'ni ni-cart text-primary',
            path: '/orders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.create auction'),
            icon: 'ni fas fa-plus text-primary',
            path: '/order-form',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.create order to company'),
            icon: 'ni fas fa-plus text-primary',
            path: '/order-to-company',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.barcode_prints'),
            num: $t('global.new'),
            icon: 'ni fas fa-barcode text-primary',
            path: '/print-barcode',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.delivery offers'),
            icon: 'ni fab fa-buffer text-primary',
            path: '/delivery-offers',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.pending orders'),
            num: pending_orders,
            icon: 'ni fas fa-hourglass-half text-primary',
            path: '/pending-orders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.menu transactions'),
            icon: 'ni ni-settings-gear-65 text-primary',
            path: '/menu-transactions',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.invoice_design'),
            icon: 'ni fas fa-file text-primary',
            path: '/invoice-design',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    pending_orders() {
      return this.$store.getters.pendingOrders;
    },
    get_trader_name() {
      return JSON.parse(localStorage.getItem("nqalTraderLoginData")).name;
    },
    get_trader_logo() {
      return JSON.parse(localStorage.getItem("nqalTraderLoginData"))
        .trader_logo;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
