<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-8">
          <div class="card shadow border-0 bg-default">
            <div class="card-header border-0 bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-white">
                    #{{ $t("orders.track order") }} {{ orderInfo.id }} to
                    {{ orderInfo.address }} -
                    {{ orderInfo.state }}
                  </h3>
                </div>
              </div>
            </div>

            <div id="map" class="map-canvas" style="height: 600px"></div>

            <div class="table-responsive">
              <table
                class="table tablesorter table align-items-center table-flush table-dark"
              >
                <tbody>
                  <tr>
                    <th>{{ $t("form.receiver name") }}:</th>
                    <td>{{ orderInfo.receiver_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("form.receiver phone") }}:</th>
                    <td>{{ orderInfo.receiver_phone }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("form.receiver address") }}:</th>
                    <td>{{ orderInfo.address + " - " + orderInfo.state }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("form.price") }}:</th>
                    <td>{{ orderInfo.price }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("global.payment method") }}:</th>
                    <td>{{ orderInfo.payment_methods_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("tables.courier name") }}:</th>
                    <td>{{ orderInfo.courier_name }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("orders.shipping status") }}:</th>
                    <td class="ar-ltr">
                      <span
                        v-for="(status, index) in orderInfo.statuses"
                        :key="index"
                      >
                        <span
                          class="badge text-white"
                          :class="
                            index != orderInfo.statuses.length - 1
                              ? 'bg-danger'
                              : 'bg-success'
                          "
                        >
                          {{ status.name }}
                        </span>
                        <i
                          v-if="index != orderInfo.statuses.length - 1"
                          class="fas fa-arrow-right text-primary mx-2"
                        ></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="card shadow border-0 bg-default">
            <div class="card-header border-0 bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-white">{{ $t("orders.order chat") }}</h3>
                </div>
              </div>
            </div>
            <div v-if="noChat">
              <div class="alert bg-danger text-white mx-2">
                {{ $t("orders.no chat") }}
              </div>
            </div>
            <div class="chat-box" v-else>
              <div v-for="(msg, index) in chat" :key="index">
                <!-- Courier Message -->
                <div
                  class="courier-msg d-flex align-items-center"
                  v-if="msg.docData.senderId == orderInfo.courier_id"
                >
                  <div>
                    <span class="name">{{ msg.docData.senderName }}</span>
                    <p class="mb-0">{{ msg.docData.messageBody }}</p>
                    <span class="date">{{
                      moment(msg.docData.messageTime.toDate()).format(
                        "MMM DD, YYYY [at] HH:mm a"
                      )
                    }}</span>
                  </div>
                  <img
                    :src="
                      'https://nqal.co/TestNqal/public/' +
                      msg.docData.senderImage
                    "
                  />
                </div>
                <!-- Client Message -->
                <div class="client-msg d-flex align-items-center" v-else>
                  <img
                    src="https://nqal.co/nqal/public//uploads/traders/default.png"
                  />
                  <div>
                    <span class="name">{{ msg.docData.senderName }}</span>
                    <p class="mb-0">{{ msg.docData.messageBody }}</p>
                    <span class="date">{{
                      moment(msg.docData.messageTime.toDate()).format(
                        "MMM DD, YYYY [at] HH:mm a"
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db, collection, getDocs } from "../../firebase/firestoreDb";
import moment from "moment";

import axios from "axios";
import { Loader } from "google-maps";
const loader = new Loader("AIzaSyDx-Roz0uBFP0w6YV2RMhYEwurSAVIH3IQ");
export default {
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: "",
      orderInfo: "",
      chat: [],
      noChat: false,
    };
  },
  created() {
    this.getOrder();
    this.getOrderChat();
    this.moment = moment;
  },
  methods: {
    getOrder: function () {
      this.loading = true;
      axios
        .get("trader/traders/orders/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.orderInfo = response.data.data[0];
            loader.load().then(function (google) {
              // Regular Map
              const myLatlng = new google.maps.LatLng(41.85, -87.65); //Center
              const mapOptions = {
                zoom: 7,
                center: myLatlng,
                mapId: "2bf1cba222371325",
                scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
                disableDefaultUI: true, // a way to quickly hide all controls
                zoomControl: true,
              };

              var start = new google.maps.LatLng(
                response.data.data[0].pickup_lat,
                response.data.data[0].pickup_long
              );
              var end = new google.maps.LatLng(
                response.data.data[0].receiver_lat,
                response.data.data[0].receiver_long
              );

              var directionsService = new google.maps.DirectionsService();
              var directionsDisplay = new google.maps.DirectionsRenderer();

              const map = new google.maps.Map(
                document.getElementById("map"),
                mapOptions
              );

              directionsDisplay.setMap(map);
              directionsService.route(
                {
                  origin: start,
                  destination: end,
                  travelMode: "DRIVING",
                },
                function (response, status) {
                  if (status === "OK") {
                    directionsDisplay.setDirections(response);
                  } else {
                    window.alert("Directions request failed due to " + status);
                  }
                }
              );
            });
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    getOrderChat: function () {
      (async () => {
        const querySnapshot = await getDocs(
          collection(db, "OrderChat/" + this.$route.params.id + "/Chat")
        );
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let docData = doc.data();
            this.chat.push({ docData });
          });
        } else {
          this.noChat = true;
        }
      })();
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-box {
  background: #fff;
  height: 400px;
  padding: 10px 5px;
  overflow-y: auto;

  .client-msg {
    div {
      margin-left: 0.25rem;
    }
  }
  .courier-msg {
    justify-content: flex-end;

    div {
      margin-right: 0.25rem;
    }
  }
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .name {
    font-size: 11px;
    color: #b3b2b2;
    display: block;
  }
  .date {
    font-size: 10px;
    color: #ccc;
    display: block;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 25px;
    line-height: 1.5;
  }
  .client-msg {
    p {
      background: #65c6d1;
      color: #fff;
    }
  }
  .courier-msg {
    .name {
      display: block;
      text-align: right;
    }
    .date {
      display: block;
      text-align: right;
    }
    p {
      background: #ccc;
      color: #000;
    }
  }
}
</style>
