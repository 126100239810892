import axios from "axios";
import { messaging, getToken } from "../../firebase/firestoreDb";
export default {
  state: {
    nqalTraderToken: false,
    nqalTraderLoginData: false,
    error: "Null",
    pending_orders: localStorage.getItem("pending_orders") ?? "",
  },
  getters: {
    isLogged(state) {
      return !!state.nqalTraderToken;
    },
    isError(state) {
      return state.error;
    },
    isToken(state) {
      return state.nqalTraderToken;
    },
    memberName(state) {
      return state.nqalTraderLoginData.name;
    },
    memberAvatar(state) {
      return state.nqalTraderLoginData.avatar;
    },
    traderName(state) {
      return state.nqalTraderLoginData.trader_name;
    },
    traderPhone(state) {
      return state.nqalTraderLoginData.phone;
    },
    traderLogo(state) {
      return state.nqalTraderLoginData.trader_logo;
    },
    traderId(state) {
      return state.nqalTraderLoginData.trader_id;
    },
    pendingOrders(state) {
      return state.pending_orders;
    },
  },
  mutations: {
    setPendingOrders(state, pendingOrders) {
      state.pending_orders = pendingOrders;
    },
    setNqalTraderLoginData(state, nqalTraderData) {
      state.nqalTraderToken = nqalTraderData.access_token;
      state.nqalTraderLoginData = nqalTraderData;
      localStorage.setItem(
        "nqalTraderToken",
        JSON.stringify(nqalTraderData.access_token)
      );
      localStorage.setItem(
        "nqalTraderLoginData",
        JSON.stringify(nqalTraderData)
      );
      axios.defaults.headers.common.Authorization = `Bearer ${nqalTraderData.access_token}`;
    },
    setLoginError(state, error) {
      state.error = error;
    },
    setLogout(state) {
      state.nqalTraderToken = null;
      state.nqalTraderLoginData = null;
      state.pending_orders = "";
      // localStorage.removeItem('nqalTraderToken','nqalTraderLoginData');
    },
    // Push Notification Firebase
    pushNotification() {
      console.log("pushNotification")
      getToken(messaging, {
        vapidKey:
          "BCnz0cmtKFofnOHYy3GuZyCUFs7sGEOmeI_kLndJeL-WNQ45t45LHs6gyUawnR_GKjW3FnDUZSwUwicDEBWT1jc",
      })
        .then((currentToken) => {
          if (currentToken) {
            axios
              .post("trader/traders/updatefcmweb", {
                fcm_token_web: currentToken,
                fcm_lang_web: localStorage.getItem("userLang"),
              })
              .then((response) => {
                if (response.data.error) {
                  //
                  console.log(response.data)
                  console.log("Error")
                } else {
                  console.log("No Error")
                  console.log(response.data)
                }
              })
              .catch((error) => {
                console.log("Catch");
                console.log(error);
              })
              .finally();
          } else {
            // Show permission request UI
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
  },
  actions: {
    Login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("trader/traders/members/login", payload)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                commit("setNqalTraderLoginData", res.data.data[0]);
                commit("pushNotification");
              } else {
                commit("setLoginError", res.data.message);
              }
            }
            resolve("/dashboard");
          })
          .catch((err) => {
            reject(err);
            console.log("Something went wrong Catch => " + err);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve) => {
        axios.post("trader/traders/members/logout").then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              commit("setLogout");
              localStorage.removeItem("pending_orders");
              localStorage.removeItem("nqalTraderToken");
              localStorage.removeItem("nqalTraderLoginData");
              delete axios.defaults.headers.common["Authorization"];
            } else {
              // console.log(res.data);
            }
          }
          resolve("/login");
        });
      });
    },  
    Update({ commit }, updatedData) {
      commit("setNqalTraderLoginData", updatedData);
    }, 
    UpdatePendingOrders({ commit }, updatedData) {
      commit("setPendingOrders", updatedData);
    },
  },
};
