<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-main opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{ courier.name }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <base-loader v-if="loading"></base-loader>
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img :src="courier.avatar" class="rounded-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button
                  size="sm"
                  type="success"
                  class="mr-4"
                  v-if="courier.online"
                  >{{ $t("couriers.online") }}</base-button
                >
                <base-button size="sm" type="danger" class="mr-4" v-else>{{
                  $t("couriers.offline")
                }}</base-button>

                <base-button
                  size="sm"
                  type="danger"
                  class="float-right"
                  v-if="courier.busy"
                  >{{ $t("couriers.busy") }}</base-button
                >
                <base-button
                  size="sm"
                  type="success"
                  class="float-right"
                  v-else
                  >{{ $t("couriers.available") }}</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">{{ courier.avgRatings }}</span>
                      <span class="description">{{
                        $t("couriers.rating")
                      }}</span>
                    </div>
                    <div>
                      <span
                        class="heading badge badge-dot"
                        v-if="courier.trusted == 'Not yet'"
                      >
                        <i class="bg-danger"></i> {{ $t("global.no") }}
                      </span>
                      <span class="heading badge badge-dot" v-else>
                        <i class="bg-success"></i> {{ $t("global.yes") }}
                      </span>
                      <span class="description">{{
                        $t("global.trusted")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ courier.name }}
                  <span class="font-weight-light">
                    , {{ "#" + courier.id }}
                  </span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ courier.email }}
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ courier.phone }}
                </div>
                <hr class="my-4" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ courier.name }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">
                      {{ $t("global.info") }}
                    </a>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- License Image -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.license image") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <img :src="courier.license_image" class="w-100" />
                </div>
              </div>
              <hr class="my-4" />
              <!-- Plate Image -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.plate image") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <img :src="courier.plate_image" class="w-100" />
                </div>
              </div>
              <hr class="my-4" />
              <!-- National Id Image -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.national id image") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <img :src="courier.national_id" class="w-100" />
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "courier-profile",
  data() {
    return {
      renderComponent: false,
      loading: false,
      courier: "",
    };
  },
  created() {
    this.getCourier();
  },
  methods: {
    getCourier: function () {
      this.loading = true;
      axios
        .get("trader/traders/couriers/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.courier = response.data.data[0];
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
img{
  height: 180px;
  width: 180px;
  object-fit: cover;
}
</style>