<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("users.new role") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("users.role information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="new_role.role_name"
                    />
                  </div>
                </div>
                <hr class="my-4" />
                <div class="form-group has-label">
                  <label class="form-control-label d-block">
                    {{ $t("form.permissions") }}
                  </label>
                  <div class="row">
                    <div
                      class="col-lg-6"
                      v-for="(permission, index) in all_permissions"
                      :key="index"
                    >
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'id_' + index"
                          :value="permission.id"
                          v-model="new_role.permissions"
                        />
                        <label
                          class="form-check-label mx-2"
                          :for="'id_' + index"
                        >
                          {{ permission.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addRole"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "new-role",
  data() {
    return {
      loading: false,
      validationErrors: [],
      new_role: {
        role_name: "",
        permissions: [],
      },
      all_permissions: [],
    };
  },
  created() {
    this.getPermissions();
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (this.new_role.role_name) {
        return true;
      } else {
        this.validationErrors.push(
          `${this.$t("validation.role name required")}`
        );
        return false;
      }
    },
    // Add Role
    addRole: function () {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      } else {
        this.loading = true;
        let formData = this.toFormData();
        axios
          .post("trader/traders/members/roles/store", formData)
          .then((response) => {
            if (response.data.error) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
              });
            } else {
              if (response.data.status == 0) {
                this.validationErrors.push(response.data.message);
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: `${this.$t("validation.faild")}`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: `${this.$t("validation.success")}`,
                  text: response.data.message,
                  showConfirmButton: false,
                  timer: 1200,
                });
                this.$router.push({
                  name: "roles",
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: `${this.$t("validation.check network")}`,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get All Permissions
    getPermissions: function () {
      this.loading = true;
      axios
        .get("trader/traders/members/permissions/index")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.all_permissions = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      fd.append("role_name", this.new_role.role_name);
      for (var permission in this.new_role.permissions) {
        fd.append(
          "permissions[" + permission + "][id]",
          this.new_role.permissions[permission]
        );
      }
      return fd;
    },
  },
};
</script>
