<template>
  <button
    @click="exportToExcel()"
    class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
  >
    <i class="far fa-file-excel"></i> {{ $t("orders.export_to_excel") }}
  </button>
</template>
<script>
import exportFromJSON from "export-from-json";
export default {
  name: "Excel Export",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    exportFromJSON,
  },
  data() {
    return {};
  },
  props: {
    sheet_rows: [String, Number],
    removed_columns: [String, Number],
  },
  created() {
    console.log("teat");
  },
  methods: {
    exportToExcel() {
      if (this.removed_columns) {
        this.sheet_rows.forEach((object) => {
          for (let column of this.removed_columns) {
            delete object[column];
          }
        });
      }
      const data = this.sheet_rows;
      const fileName = "report-sheet";
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });
    },
  },
};
</script>
