import User from "./modules/user"
import Menu from "./modules/menu"

import { createStore } from "vuex";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: User,
    menu: Menu,
  },
});
