<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("menu.menu activation") }}</h3>
                  </div>
                </div>
              </div>
            </template>
            <form @submit.prevent="submitForm">
              <h6 class="heading-small text-muted mb-4">
                {{ $t("menu.change menu status") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <input
                      class="apple-switch"
                      type="checkbox"
                      true-value="1"
                      false-value="0"
                      v-model="menuStatus"
                    />
                  </div>
                </div>
                <div class="row" v-if="traderMenu">
                  <div class="col-12">
                    <a
                      :href="traderMenu"
                      target="_blank"
                      class="btn btn-primary mt-3"
                    >
                      {{ traderMenu }}
                    </a>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.trader_vat_included") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="trader_vat_included"
                          true-value="1"
                          false-value="0"
                          v-model="vat"
                        />
                        <label
                          class="form-check-label"
                          for="trader_vat_included"
                        >
                          {{ $t("form.trader_vat_included") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.have_shop_place") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="have_shop_place"
                          true-value="1"
                          false-value="0"
                          v-model="have_shop_place"
                        />
                        <label class="form-check-label" for="have_shop_place">
                          {{ $t("form.have_shop_place") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("menu.menu_cover") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("menu.menu_cover") }} (480 * 260)
                      </label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadCoverBg"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 text-center menuCover" v-if="menuCover">
                    <img :src="menuCover" />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.trader_menu_available") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="trader_menu_available"
                          true-value="1"
                          false-value="0"
                          v-model="available"
                        />
                        <label
                          class="form-check-label"
                          for="trader_menu_available"
                        >
                          {{ $t("form.trader_menu_available") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.receive_order_takeAway") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="receive_order_takeAway"
                          true-value="1"
                          false-value="0"
                          v-model="receive_order_takeAway"
                        />
                        <label
                          class="form-check-label"
                          for="receive_order_takeAway"
                        >
                          {{ $t("form.receive_order_takeAway") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("menu.delivery_time") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="now"
                          value="now_only"
                          v-model="order_date_type"
                        />
                        <label class="form-check-label" for="now">
                          {{ $t("menu.now") }}
                        </label>
                      </div>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="custom"
                          value="custom_only"
                          v-model="order_date_type"
                        />
                        <label class="form-check-label" for="custom">
                          {{ $t("menu.custom_date") }}
                        </label>
                      </div>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="now_custom"
                          value="now_&_custom"
                          v-model="order_date_type"
                        />
                        <label class="form-check-label" for="now_custom">
                          {{ $t("menu.now_custom") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("menu.minimum_cart_value") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('menu.minimum_cart_value')"
                      :placeholder="$t('menu.minimum_cart_value')"
                      input-classes="form-control-alternative"
                      v-model="minimum_cart_value"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("menu.payment_type") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="cashPaymentOnly"
                          value="cashPaymentOnly"
                          v-model="payment_type"
                        />
                        <label class="form-check-label" for="cashPaymentOnly">
                          {{ $t("menu.cashPaymentOnly") }}
                        </label>
                      </div>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="onlinePaymentOnly"
                          value="onlinePaymentOnly"
                          v-model="payment_type"
                        />
                        <label class="form-check-label" for="onlinePaymentOnly">
                          {{ $t("menu.onlinePaymentOnly") }}
                        </label>
                      </div>
                      <br />
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="together"
                          value="together"
                          v-model="payment_type"
                        />
                        <label class="form-check-label" for="together">
                          {{ $t("menu.together") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <button type="submit" class="btn btn-success d-block">
                {{ $t("form.save") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "new-item",
  data() {
    return {
      loading: false,
      menuStatus: 0,
      vat: 0,
      receive_order_takeAway: 0,
      available: 0,
      have_shop_place: 0,
      menuCover: "",
      menuCoverFile: "",
      traderMenu: "",
      order_date_type: "",
      payment_type: "",
      minimum_cart_value: "",
    };
  },
  created() {
    this.getMenuSettings();
  },
  methods: {
    getMenuSettings: function () {
      this.loading = true;
      axios
        .get("trader/traders/show")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.cant get trader information")}`,
            });
          } else {
            this.menuStatus =
              response.data.data[0].allowMenu == "allow" ? 1 : 0;
            this.traderMenu =
              response.data.data[0].allowMenu == "allow"
                ? `https://nqal.co/en/menu/${response.data.data[0].name}`
                : 0;
            this.available = response.data.data[0].available;
            this.vat = response.data.data[0].vat_included;
            this.order_date_type = response.data.data[0].order_date_type;
            this.have_shop_place = response.data.data[0].have_shop_place;
            this.minimum_cart_value = response.data.data[0].minimum_cart_value;
            this.payment_type = response.data.data[0].payment_type;
            this.receive_order_takeAway =
              response.data.data[0].receive_order_takeAway;
            this.menuCover =
              "https://nqal.co/LiveNqal/public/uploads/traders/" +
              response.data.data[0].cover_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    submitForm: function () {
      this.loading = true;
      let formData = this.toFormData();
      axios
        .post("trader/traders/updateTraderSettings", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: "Faild",
              text: "Please try again later",
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.message,
                showConfirmButton: true,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    uploadCoverBg(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.menuCoverFile = files[0];
    },
    toFormData: function () {
      var fd = new FormData();
      fd.append("_method", "put");
      fd.append("menu", this.menuStatus);
      fd.append("vat_included", this.vat);
      fd.append("receive_order_takeAway", this.receive_order_takeAway);
      fd.append("available", this.available);
      fd.append("have_shop_place", this.have_shop_place);
      fd.append("order_date_type", this.order_date_type);
      fd.append("minimum_cart_value", this.minimum_cart_value);
      fd.append("payment_type", this.payment_type);
      if (this.menuCoverFile) {
        fd.append("cover_page", this.menuCoverFile);
      }
      return fd;
    },
  },
};
</script>
<style scoped>
.menuCover img {
  height: 260px;
}
</style>
