<template>
  <div class="main-content bg-default">
    <nav
      class="navbar navbar-expand-md navbar-top navbar-horizontal navbar-dark"
    >
      <div class="px-4 container">
        <SwitchLanguage />
      </div>
    </nav>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 mb-5 mb-lg-0">
      <div class="container">
        <div class="header-body text-center mb-3">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white">{{ $t("login.welcome to nqal") }}!</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import SwitchLanguage from "@/components/SwitchLanguage.vue";
export default {
  name: "auth-layout",
  components: {
    SwitchLanguage,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  min-height: 100vh;
}
</style>
