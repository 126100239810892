<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ $t("sidebar.categories") }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="categories"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.items num") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img :src="row.item.image" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            <!-- {{ row.item.items.length }} -->
          </td>
          <td class="text-right">
            <router-link
              :to="`/category/${row.item.id}`"
              class="btn btn-primary btn-sm"
              >{{ $t("tables.items") }} <i class="fas fa-eye"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <!-- <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      /> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "categories-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      categories: "",
      // page: 1,
      // total_pages: "",
    };
  },
  created() {
    this.getCategories("trader/trader/hasSubtraders");
  },
  // watch: {
  //   page: function () {
  //     this.getCouriers("trader/subTraderTypes/index?page=" + this.page);
  //   },
  // },
  methods: {
    getCategories: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.categories = response.data.data;
            // this.page = response.data.meta.current_page;
            // this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
