<template>
  <base-loader v-if="loading"></base-loader>
  <!-- For Print Only -->
  <div class="print" id="printMe" v-if="shownInvoice">
    <div class="invoice-top-header p-4 d-flex justify-content-between">
      {{ $t("global.nqal") }}
      <div class="no-print">
        <button
          @click="printOrderInvoice()"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-print"></i> {{ $t("orders.print invoice") }}
        </button>
        <!-- <button
          @click="printOrderSmInvoice()"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-print"></i> {{ $t("orders.print sm invoice") }}
        </button> -->
        <button
          @click="shownInvoice = false"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-long-arrow-alt-left"></i> {{ $t("global.back") }}
        </button>
      </div>
    </div>
    <div class="trader-invoice pt-4 pb-0 px-4">
      <header
        class="trader-invoice-head d-flex align-items-center justify-content-between py-4"
      >
        <div class="d-flex align-items-center">
          <img :src="trader_logo" alt="Logo" />
          <h3 class="mb-0">{{ trader_name }}</h3>
        </div>
        <h1 class="mb-0">الفاتورة</h1>
      </header>
      <hr class="mt-3 mb-5" />
      <div class="trader-invoice-body">
        <div class="trader-invoice-head-info d-flex justify-content-between">
          <div>
            <h4>رقم الفاتورة: {{ orderInfo.id }}</h4>
            <h4>التاريخ: {{ orderInfo.created_at }}</h4>
            <h1 class="mt-3">
              المبلغ الكلى <br /><strong>{{ orderInfo.price }}</strong>
            </h1>
          </div>
          <div>
            <h4>فاتورة إلى</h4>
            <h2>{{ orderInfo.receiver_name }}</h2>
            <h4>{{ orderInfo.receiver_phone }}</h4>
            <h4>{{ orderInfo.receiver_address }}</h4>
          </div>
        </div>
        <div class="trader-invoice-items mt-4">
          <div class="table-responsive">
            <table class="table invoice-table">
              <thead>
                <tr class="border-0">
                  <th>#</th>
                  <th>{{ $t("form.name") }}</th>
                  <th>{{ $t("tables.price") }}</th>
                  <th>{{ $t("tables.quantity") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in order" :key="index">
                  <td>{{ item["item"].id }}</td>
                  <td>{{ item["item"].title }}</td>
                  <td>{{ item["item"].price }}</td>
                  <td>{{ item.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="trader-invoice-payment d-flex justify-content-between mt-5">
          <div>
            <h2>بيانات الدفع:</h2>
            <h4>{{ orderInfo.payment_methods_name }}</h4>
          </div>
          <div>
            <h4>السعر: 102</h4>
            <h4>الضريبة المضافة: 55</h4>
            <hr class="my-3" />
            <h1>السعر الكلى: {{ orderInfo.price }}</h1>
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-5" />
      <footer class="mt-5 text-center py-2 invoice-footer">
        <h2>شكراً لشرائك</h2>
        <p class="mb-0">{{ $t("global.thanks") }}</p>
        <div class="ar-ltr">
          <a href="#" class="mx-2"
            ><i class="fas fa-globe"></i> https://www.nqal.co</a
          >
          <!-- <a href="tel:01001010" class="mx-2"><i class="fas fa-phone"></i> 010101010</a> -->
          <a href="info@nqal.co" class="mx-2"
            ><i class="fas fa-envelope"></i> info@nqal.co</a
          >
        </div>
        <div v-if="orderInfo.order_serial" class="barcode-svg">
          <barcode-generator
            :value="orderInfo.order_serial"
            :format="'CODE39'"
            :lineColor="'#000'"
            :height="'40'"
            :width="'2'"
            :elementTag="'svg'"
          />
        </div>
      </footer>
    </div>
  </div>
  <!-- Small Print -->
  <div v-if="orderInfo" class="print printSm hidden" id="printSm">
    <barcode-invoice :orderInfo="orderInfo"></barcode-invoice>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex align-items-center justify-content-between"
        >
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("orders.assign to courier") }}
          </h5>
          <button
            type="button"
            id="close"
            class="close m-0 p-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="closest-courier" v-if="closest_courier">
            <h4>{{ $t("tables.nearest courier") }}</h4>
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="d-flex align-items-center">
                <img :src="closest_courier.avatar" />
                <p class="mb-0">{{ closest_courier.name }}</p>
              </div>
              <button
                class="btn btn-success"
                @click="assignOrderToCourier(closest_courier.id)"
              >
                {{ $t("orders.assign") }}
              </button>
            </div>
            <hr class="my-4" />
          </div>
          <h4>{{ $t("tables.trader couriers") }}</h4>
          <select
            class="form-control"
            v-if="couriers.length > 1"
            @change="assignOrderToCourier($event)"
          >
            <option value="">{{ $t("orders.assign to courier") }}</option>
            <option
              v-for="(courier, index) in couriers"
              :key="index"
              :value="courier.id"
            >
              {{ courier.name }}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ $t("global.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="card shadow mb-4" v-if="shownInvoice == false">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-default' : ''"
      >
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ $t("orders.order details") }}
            </h3>
          </div>
          <div class="col-md-8 text-right d-flex justify-content-end">
            <input
              :value="$t('orders.generate invoice')"
              type="button"
              @click="shownInvoice = true"
              class="btn btn-primary btn-sm print-btn"
            />
            <button
              @click="printBarCode()"
              class="btn btn-primary btn-sm print-btn"
            >
              <i class="fas fa-barcode"></i> {{ $t("orders.print_barcode") }}
            </button>
            <button
              v-if="
                orderInfo.order_type == 'delviery' &&
                orderInfo.status_id == 1 &&
                (orderInfo.courier_id == 0 || orderInfo.courier_id == null)
              "
              type="button"
              class="btn btn-success btn-sm"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              {{ $t("orders.assign to courier") }}
            </button>
            <div
              class="d-flex"
              v-if="
                (orderInfo.order_type == 'takeAwy' &&
                  orderInfo.status_id == 1) ||
                orderInfo.status_id == 2
              "
            >
              <h5
                class="btn-sm mb-0 mx-2 bg-warning p-1 text-white"
                v-if="orderInfo.status_id == 1"
              >
                {{ $t("orders.being_processed") }}
              </h5>
              <h5
                class="btn-sm mb-0 mx-2 bg-warning p-1 text-white"
                v-if="orderInfo.status_id == 2"
              >
                {{ $t("orders.order_pickup") }}
              </h5>
              <input
                type="button"
                :value="$t('orders.change status')"
                @click="changeTakeawayStatus(orderInfo.id)"
                class="btn btn-success btn-sm print-btn"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table tablesorter table align-items-center table-flush">
          <!-- Table Head -->
          <tbody>
            <tr>
              <th>
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("form.receiver name") }}
              </th>
              <td>{{ orderInfo.receiver_name }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-mobile-button mr-2 text-main"></i>
                {{ $t("form.receiver phone") }}
              </th>
              <td>{{ orderInfo.receiver_phone }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("form.receiver address") }}
              </th>
              <td>{{ orderInfo.state + " - " + orderInfo.address }}</td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-money-bill-wave-alt mr-2 text-main"></i>
                {{ $t("form.price") }}
              </th>
              <td>{{ orderInfo.price }}</td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-credit-card mr-2 text-main"></i>
                {{ $t("global.payment method") }}
              </th>
              <td>{{ orderInfo.payment_methods_name }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-user-run mr-2 text-main"></i>
                {{ $t("tables.courier name") }}
              </th>
              <td>{{ orderInfo.courier_name }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-delivery-fast mr-2 text-main"></i>
                {{ $t("orders.shipping status") }}
              </th>
              <td class="ar-ltr">
                <span
                  v-for="(status, index) in orderInfo.statuses"
                  :key="index"
                >
                  <span
                    class="badge text-white"
                    :class="
                      index != orderInfo.statuses.length - 1
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                  >
                    {{ status.name }}
                  </span>
                  <i
                    v-if="index != orderInfo.statuses.length - 1"
                    class="fas fa-arrow-right text-main mx-2"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Order Items -->
    <div
      class="card shadow"
      :class="type === 'dark' ? 'bg-default' : ''"
      v-if="shownInvoice == false"
    >
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ title }}
            </h3>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="order"
        >
          <!-- Table Head -->
          <template v-slot:columns>
            <th>#</th>
            <th>{{ $t("global.image") }}</th>
            <th>{{ $t("form.name") }}</th>
            <th>{{ $t("tables.price") }}</th>
            <th>{{ $t("tables.quantity") }}</th>
          </template>

          <!-- Table Body -->
          <template v-slot:default="row">
            <td class="budget">
              {{ row.item["item"].id }}
            </td>
            <td class="budget">
              <img
                :src="row.item['item'].images[0]"
                :alt="row.item['item'].title + ' Image'"
              />
            </td>
            <td class="budget">
              {{ row.item["item"].title }}
            </td>
            <td class="budget">
              {{ row.item["item"].price }}
            </td>
            <td class="budget">
              {{ row.item.quantity }}
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import BarcodeInvoice from "../../../components//BarcodeInvoice.vue";
import BarcodeGenerator from "../../../components/BarcodeGenerator.vue";

export default {
  name: "order-table",
  components: {
    BarcodeInvoice,
    BarcodeGenerator,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      shownInvoice: false,
      shownSmInvoice: false,
      orderInfo: "",
      order: "",
      couriers: "",
      couriers_id_name: {},
      closest_courier: "",
      qrValue: "",
    };
  },
  created() {
    this.getOrder();
    this.moment = moment;
  },
  computed: {
    trader_name() {
      return this.$store.getters.traderName;
    },
    trader_phone() {
      return this.$store.getters.traderPhone;
    },
    trader_logo() {
      return this.$store.getters.traderLogo;
    },
    total_order_price() {
      let total_order_price = 0;
      for (let item of this.order) {
        total_order_price += parseFloat(item.quantity * item.item.price);
      }
      return total_order_price;
    },
  },
  methods: {
    getOrder: function () {
      this.loading = true;
      axios
        .get("trader/traders/orders/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            console.log(response.data.data[0]);
            this.orderInfo = response.data.data[0];
            this.qrValue = response.data.data[0].qr_code;
            this.order = response.data.data[0].items;
            if (this.orderInfo.order_type == "delviery") {
              this.getCouriers();
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Couriers to assign
    getCouriers: function () {
      this.loading = true;
      axios
        .get("trader/orders/nearCouriersCompanies/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.couriers = response.data.data["trader_couriers"];
            if (response.data.data["closest_courier"]) {
              this.closest_courier = response.data.data["closest_courier"];
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Assign Order to Courier
    assignOrderToCourier: function ($closestCourier = null) {
      this.loading = true;
      axios
        .post("trader/orders/assignOrder", {
          order_id: this.orderInfo.id,
          courier_id: event.target.value ? event.target.value : $closestCourier,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.msg,
              });
            } else {
              document.getElementById("close").click();
              this.getOrder();
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Change Takeaway Order Status
    changeTakeawayStatus: function (order_id) {
      this.loading = true;
      let newStatus;
      if (this.orderInfo.status_id == 1) {
        newStatus = 2;
      } else if (this.orderInfo.status_id == 2) {
        newStatus = 4;
      }
      axios
        .post("trader/traders/orders/statusChange", {
          order_id: order_id,
          status_id: newStatus,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.msg,
              });
            } else {
              this.getOrder();
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Print Invoice
    printOrderInvoice() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
    printOrderSmInvoice() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printSm").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
    // Print Barcode
    printBarCode() {
      const prtHtml = document.getElementById("printSm").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
            @media print {
              @page {
                size: 4in 6in;
                margin: 0;
                padding: 0;
              }
              html, body { 
                font-family: 'Roboto', sans-serif;
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 97%;
                margin: 0;
                padding: 0;
              }
              svg {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
            // Print Sm
            .sm-invoice {
              color: #000;
              background-color: #fff;
              width: 95mm;
              height: auto;
              border: 1px solid #000;
              border-radius: 8px;
              padding: 12px;
              direction: ltr;
              text-align: start;
            }
            .sm-invoice img {
              width: 80px;
              height: auto;
              object-fit: contain;
            }
            .sm-invoice .separator {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 6px;
              width: 1px;
              height: 100%;
              background-color: #000;
            }
            .printSm-box {
              border-bottom: 1px solid #000;
            }
            .printSm-box h6 {
              font-size: 14px;
              font-weight: 400;
            }
            .printSm-box p {
              font-size: 13px;
              font-weight: 600;
            }
            .printSm-box .in {
              width: 30px;
              height: 30px;
              background: #000;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            .no-b-b {
              border-bottom: 0 !important;
            }
            .sm-invoice svg {
              width: 100%;
            }
            .bg-gradient-main{
              background: linear-gradient(87deg, #65d1d1 0, #65a4d1 100%) !important;
              color: #fff;
              text-align: center;
              font-weight: 700;
            }
          </style>           
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.print();
    },
  },
};
</script>

<style lang="scss">
.closest-courier {
  text-align: start;
}
.closest-courier img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.closest-courier h4 {
  font-size: 18px;
  font-weight: 700;
  border-radius: 50%;
}
.closest-courier p {
  font-size: 20px;
  font-weight: 600;
  margin-inline-start: 10px;
}
img {
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.trader-invoice > * {
  color: #111;
}
.invoice-top-header {
  background-color: #3a3d50;
  color: #fff;
  font-size: 20px;
}
.trader-invoice {
  background-color: #fff;
}
.trader-invoice hr {
  border-top: 2px solid #000;
}
.invoice-table th {
  font-weight: bold;
  color: #333;
  font-size: 18px;
}
.invoice-table td,
.invoice-table th {
  vertical-align: middle;
  white-space: nowrap;
}
.invoice-table img {
  max-width: 70px;
}
.invoice-body .total-invoice {
  background-color: #f7f7f7;
}
.invoice-body .total-invoice td.last-td {
  background-color: #3a3d50 !important;
  color: #fff;
  font-size: 22px;
}
.invoice-footer {
  border-top: 1px solid #eee;
}
.invoice-footer a {
  color: #555;
}
.invoice-footer a svg {
  color: #576677;
}
.hidden {
  display: none;
}
// @media only screen {
//   .printSm{
//     display: none;
//   }
// }
// .sm-invoice {
//   color: #000;
//   background-color: #fff;
//   width: 95mm;
//   height: auto;
//   border: 1px solid #000;
//   border-radius: 8px;
//   padding: 12px;
//   direction: ltr;
//   text-align: start;
// }
// .sm-invoice img {
//   width: 60px;
//   height: 60px;
//   object-fit: contain;
// }
// .sm-invoice .separator {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 6px;
//   width: 1px;
//   height: 100%;
//   background-color: #000;
// }
// .printSm-box {
//   border-bottom: 1px solid #000;
//   padding-bottom: 6px;
// }
// .printSm-box p{
//   border-bottom: 1px solid #000;
//   font-size: 13px;
//   font-weight: 600;
// }
// .no-b-b{
//   border-bottom: 0 !important;
// }
// @media print{
//   html, body {
//     width: 95mm;
//     height: 95mm;
//   }
//   @page {
//     size: 95mm 95mm; /* landscape */
//   }
// }
</style>
