<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="total"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>{{ $t("tables.total invoices") }}</th>
          <th>{{ $t("tables.total cash") }}</th>
          <th>{{ $t("tables.total months") }}</th>
          <th>{{ $t("tables.total weeks") }}</th>
          <th>{{ $t("tables.total days") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.total_invoices }}
          </td>
          <td class="budget">
            {{ row.item.total_cach }}
          </td>
          <td class="budget">
            {{ row.item.total_months }}
          </td>
          <td class="budget">
            {{ row.item.total_weeks }}
          </td>
          <td class="budget">
            {{ row.item.total_days }}
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "traders-total-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      total: "",
    };
  },
  created() {
    this.getTotal();
  },
  methods: {
    getTotal: function () {
      this.loading = true;
      axios
        .get("trader/traders/reports/traderInvoicesGenerateTotal")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.total = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
