<template>
  <div class="main-content">
    <!-- Page content -->
    <div class="px-1 pb-5">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "menu-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  min-height: 100vh;
  background-color: #f0f8ff70;
}
</style>
