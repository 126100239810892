<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">
              {{ $t("wallet.my balance") }}: {{ wallet }} {{ $t("wallet.aed") }}
            </h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-6 order-xl-2 mb-5 mb-xl-0">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <span class="btn-sm btn-primary">
                      {{ $t("wallet.my balance") }}: {{ wallet }}
                      {{ $t("wallet.aed") }}
                    </span>
                  </div>
                  <div class="col-4 text-right">
                    <span class="btn-sm btn-primary">
                      {{ $t("wallet.charge wallet") }}
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <charge-table
              type="dark"
              :title="$t('wallet.wallet')"
            ></charge-table>
          </card>
        </div>

        <div class="col-xl-6 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <!-- <h3 class="mb-0">{{courier.name}}</h3> -->
                  </div>
                  <div class="col-4 text-right">
                    <span class="btn-sm btn-primary">
                      {{ $t("wallet.my transactions") }}
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <wallet-table
              type="dark"
              :title="$t('wallet.wallet')"
            ></wallet-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ChargeTable from "../Tables/Wallet/ChargeTable.vue";
import WalletTable from "../Tables/Wallet/WalletTable.vue";

export default {
  name: "wallet",
  data() {
    return {
      wallet: "",
    };
  },
  components: {
    WalletTable,
    ChargeTable,
  },
  created() {
    this.getWallet();
  },
  methods: {
    getWallet: function () {
      this.loading = true;
      axios
        .get("trader/traders/show")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.cant get trader information")}`,
            });
          } else {
            console.log(response.data.data[0]);
            this.wallet = response.data.data[0].wallet;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style></style>
