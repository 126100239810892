<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <select
            class="btn btn-primary btn-sm text-left"
            @change="getTransactions($event)"
          >
            <option value="1">
              {{ $t("wallet.successfull transaction") }}
            </option>
            <option value="2">{{ $t("wallet.faild transaction") }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="transactions"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("wallet.transaction id") }}</th>
          <th>{{ $t("wallet.amount") }}</th>
          <th>{{ $t("tables.date") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.payment_id }}
          </td>
          <td class="budget">
            {{ row.item.amount }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "wallet-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      transactions: "",
      traderId: "",
      status: 1,
    };
  },
  created() {
    this.traderId = JSON.parse(
      localStorage.getItem("nqalTraderLoginData")
    ).trader_id;
    this.getTransactions();
    this.moment = moment;
  },
  methods: {
    getTransactions: function () {
      this.loading = true;
      if (event) {
        this.status = event.target.value;
      }
      axios
        .post("general/transactioncategorized", {
          id: this.traderId,
          type: "trader",
          transaction: this.status,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.transactions = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
