<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-1 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.general_orders") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  traderInfo.orders_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-sound-wave"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/general-orders"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>
                  {{ $t("orders.all orders") }}
                </span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-2 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.menu_orders") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  traderInfo.orders_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-sound-wave"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/orders"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>
                  {{ $t("orders.all orders") }}
                </span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-3 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("items.items") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">
                  {{ traderInfo.items_cnt }}
                </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-single-copy-04"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/items"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("items.all items") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-4 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.create auction") }}
                </h5>
                <span class="h2 font-weight-bold mb-0"> - </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni fas fa-plus"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/order-form"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.create auction") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-5 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.create order to company") }}
                </h5>
                <span class="h2 font-weight-bold mb-0"> - </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni fas fa-plus"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/order-to-company"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.create order to company") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-6 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.delivery offers") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  traderInfo.couriers_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni fab fa-buffer"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/delivery-offers"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.delivery offers") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-7 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.menu settings") }}
                </h5>
                <span class="h2 font-weight-bold mb-0"> - </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni-settings-gear-65"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/settings/menu"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.menu settings") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-8 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.companies") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  traderInfo.couriers_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni fas fa-building"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/companies"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.companies") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-9 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.heat_map") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">
                  {{ $t("global.soon") }}
                </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="fas fa-map-marked-alt"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3">
          <div class="card card-10 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("sidebar.barcode_prints") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">-</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="fas fa-barcode"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/print-barcode"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("sidebar.barcode_prints") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <!-- <div class="container-fluid mt--7">
      <div class="card shadow border-0 bg-default">
        <div class="card-header border-0 bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-white">{{ $t("couriers.couriers") }}</h3>
            </div>
          </div>
        </div>
        <div id="map" class="map-canvas" style="height: 600px"></div>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: "",
      traderInfo: "",
      couriers: "",
    };
  },
  created() {
    this.getTraderInfo();
  },
  methods: {
    getTraderInfo: function () {
      this.loading = true;
      axios
        .get("trader/traders/show", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.traderInfo = response.data.data[0];
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style>
.customMarker {
  position: absolute;
  cursor: pointer;
  background: #65d1d1;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -70px;
  border-radius: 50%;
  padding: 0px;
}
.customMarker:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 19px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #65d1d1 transparent;
  display: block;
  width: 0;
}
.customMarker img {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
}
.courier-info {
  padding: 10px 15px;
}
.courier-info .courier-name {
  color: #222;
  margin-bottom: 20px;
}
.courier-info a {
  background-color: #65d1d1;
  color: #fff;
  padding: 6px 14px;
  border-radius: 4px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}
.courier-info a:hover {
  background-color: #005bea;
  color: #fff;
}
.card {
  box-shadow: -5px 6px 25px 0 #65a4d1;
  border: 0;
}
.header .card-1 {
  background-image: linear-gradient(
    to left,
    #0db2de 0%,
    #005bea 100%
  ) !important;
}
.header .card-2 {
  background-image: linear-gradient(
    to left,
    #ffc107 0%,
    #daa608 100%
  ) !important;
}
.header .card-3 {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}
.header .card-4 {
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}
.header .card-5 {
  background-image: linear-gradient(270deg, #bc5fef, #f72de1) !important;
}
.header .card-6 {
  background-image: linear-gradient(270deg, #5fef90, #2df771) !important;
}
.header .card-7 {
  background-image: linear-gradient(87deg, #5e72e4, #825ee4) !important;
}
.header .card-8 {
  background-image: linear-gradient(87deg, #8965e0, #bc65e0) !important;
}
.header .card-9 {
  background-image: linear-gradient(87deg, #92fb40, #60a851) !important;
}
.header .card-10 {
  background-image: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
.header .card h5 {
  color: #fff;
  font-size: 12px;
}
.header .card-body span {
  color: #fff;
  font-size: 22px;
}
.header-card-footer {
  border-top: 1px solid #fff;
}
.header-card-footer a,
.header-card-footer a:hover {
  color: #fff;
}
.header-card-footer a span {
  font-size: 15px;
}
</style>
