import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import MenuLayout from "@/layout/MenuLayout";

import Dashboard from "../views/Dashboard.vue";

// Manage Users
import Users from "../views/User/Users.vue";
import NewUser from "../views/User/NewUser.vue";
import Roles from "../views/User/Roles.vue";
import AddRole from "../views/User/AddRole.vue";
import EditRole from "../views/User/EditRole.vue";

// Import Customers
import Customers from "../views/Customers/Customers.vue";

// Import Categories
import Categories from "../views/Categories/Categories.vue";
import CategoryItems from "../views/Categories/CategoryItems.vue";

import Items from "../views/Items/Items.vue";
import ItemForm from "../views/Items/ItemForm.vue";

import Orders from "../views/Orders/Orders.vue";
import GeneralOrders from "../views/Orders/GeneralOrders.vue";
// import NewOrder from "../views/Orders/NewOrder.vue";
import OrderForm from "../views/Orders/OrderForm.vue";
import OrderToCompany from "../views/Orders/OrderToCompany.vue";
import Order from "../views/Orders/Order.vue";
import OrderTrack from "../views/Orders/OrderTrack.vue";

import DeliveryOffers from "../views/Orders/DeliveryOffers.vue";
import PendingOrders from "../views/Orders/PendingOrders.vue";

import MenuTransactions from "../views/Orders/MenuTransactions.vue";

import Deals from "../views/Deals/Deals.vue";
import DealForm from "../views/Deals/DealForm.vue";
import Wallet from "../views/Wallet/Wallet.vue";

import TraderMenu from "../views/Menu/TraderMenu.vue";
// Import Settings
import Menu from "../views/Settings/Menu.vue";
import UpdateProfile from "../views/Settings/UpdateProfile.vue";
import WorkingHours from "../views/Settings/WorkingHours.vue";

// Import Courier
import Couriers from "../views/Courier/Couriers.vue";
import Courier from "../views/Courier/CourierProfile.vue";
import CourierForm from "../views/Courier/CourierForm.vue";
// Import Companies
import Companies from "../views/Companies/Companies.vue";

// Reports
// Couriers Report
import GenerateCouriersReport from "../views/Reports/Couriers/GenerateCouriersReport.vue";
import GetCouriersReport from "../views/Reports/Couriers/GetCouriersReport.vue";
// Traders Report
import GenerateTradersReport from "../views/Reports/Traders/GenerateTradersReport.vue";
import GetTradersReport from "../views/Reports/Traders/GetTradersReport.vue";

import PrintBarcode from "../views/Prints/PrintBarcode.vue";
import invoiceDesign from "../views/invoiceDesign.vue";

import Login from "../views/Login.vue";
import ForgetPassword from "../views/ForgetPassword.vue";

import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      // Start Manage Users
      {
        path: "/users",
        name: "users",
        components: { default: Users },
      },
      {
        path: "/new-user",
        name: "NewUser",
        components: { default: NewUser },
      },
      {
        path: "/add-role",
        name: "addRole",
        components: { default: AddRole },
      },
      {
        path: "/roles",
        name: "roles",
        components: { default: Roles },
      },
      {
        path: "/edit-role/:id",
        name: "Edit Role",
        components: { default: EditRole },
        props: true,
      },
      // End Manage Users
      {
        path: "/customers",
        name: "customers",
        components: { default: Customers },
      },
      {
        path: "/categories",
        name: "categories",
        components: { default: Categories },
      },
      {
        path: "/category/:id",
        name: "categoryItems",
        component: CategoryItems,
      },

      {
        path: "/items",
        name: "items",
        components: { default: Items },
      },
      {
        path: "/item-form/:id?",
        name: "item form",
        components: { default: ItemForm },
      },
      // Start Orders
      {
        path: "/general-orders",
        name: "general-orders",
        components: { default: GeneralOrders },
      },
      {
        path: "/orders",
        name: "orders",
        components: { default: Orders },
      },
      {
        path: "/orders/:id",
        name: "order",
        components: { default: Order },
      },
      {
        path: "/orders/track/:id",
        name: "track order",
        components: { default: OrderTrack },
      },
      {
        path: "/order-form/:id?",
        name: "Order Form",
        components: { default: OrderForm },
        props: true,
      },
      {
        path: "/order-to-company/:id?",
        name: "Order To Company",
        components: { default: OrderToCompany },
        props: true,
      },
      {
        path: "/delivery-offers/",
        name: "Delivery Offers",
        components: { default: DeliveryOffers },
      },
      {
        path: "/pending-orders/",
        name: "Pending Orders",
        components: { default: PendingOrders },
      },
      {
        path: "/menu-transactions/",
        name: "Menu Transactions",
        components: { default: MenuTransactions },
      },
      // End Orders
      // Start Deals
      {
        path: "/deals",
        name: "deals",
        components: { default: Deals },
      },
      {
        path: "/deal-form/:id?",
        name: "Deal Form",
        components: { default: DealForm },
        props: true,
      },
      // End Deals
      {
        path: "/wallet",
        name: "wallet",
        components: { default: Wallet },
      },
      // Start Settings
      {
        path: "/settings/menu",
        name: "menu",
        components: { default: Menu },
      },
      {
        path: "/settings/update-profile",
        name: "update profile",
        components: { default: UpdateProfile },
      },
      {
        path: "/settings/working-hours",
        name: "working hours",
        components: { default: WorkingHours },
      },
      // End Settings
      // Start Couriers
      {
        path: "/couriers",
        name: "couriers",
        components: { default: Couriers },
      },
      {
        path: "/couriers/:id",
        name: "courier",
        components: { default: Courier },
      },
      {
        path: "/courier-form/:id?",
        name: "Courier Form",
        components: { default: CourierForm },
        props: true,
      },
      // End Couriers
      // Start Companies
      {
        path: "/companies",
        name: "companies",
        components: { default: Companies },
      },
      // End Companies
      // Start Reports
      // Couriers Report
      {
        path: "/generate-couriers-report",
        name: "generate couriers report",
        components: { default: GenerateCouriersReport },
        props: true,
      },
      {
        path: "/get-couriers-report",
        name: "get couriers report",
        components: { default: GetCouriersReport },
        props: true,
      },
      // Traders Report
      {
        path: "/generate-traders-report",
        name: "generate traders report",
        components: { default: GenerateTradersReport },
        props: true,
      },
      {
        path: "/get-traders-report",
        name: "get traders report",
        components: { default: GetTradersReport },
        props: true,
      },
      // End Reports
      {
        path: "/print-barcode",
        name: "print barcode",
        components: { default: PrintBarcode },
        props: true,
      },
      {
        path: "/invoice-design",
        name: "invoice design",
        components: { default: invoiceDesign },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    name: "Login",
    component: AuthLayout,
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        components: { default: ForgetPassword },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
  {
    path: "/",
    redirect: "Menu",
    name: "Menu",
    component: MenuLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/trader-menu",
        name: "trader_menu",
        components: { default: TraderMenu },
      },
    ]
  }
];

const router = createRouter({
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  // routes,
  // mode: 'history'
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
import axios from "axios";
// Get Pending Orderes Count To Display In Sidebar
router.afterEach((to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    axios
      .get("trader/orders/pending?page=1")
      .then((response) => {
        if (response.data.error) {
          this.$swal({
            position: "center",
            icon: "error",
            title: `${this.$t("validation.faild")}`,
          });
        } else {
          localStorage.setItem("pending_orders", response.data.meta.total);
        }
      })
      .catch(() => {
        if (!window.navigator.onLine) {
          this.$swal({
            position: "center",
            icon: "error",
            title: `${this.$t("validation.faild")}`,
            text: `${this.$t("validation.check network")}`,
          });
        }
      })
      .finally();
  }
});
export default router;
