export default {
  state: {
    isLoading: false,
    products: [],
    product: null,
    cart: JSON.parse(localStorage.getItem("cart")) || [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    cart(state) {
      return state.cart;
    },
    cartItemCount(state) {
      return state.cart.length;
    },
    cartTotalPrice(state) {
      let total = 0;
      state.cart.forEach((item) => {
        total += item.product.price * item.quantity;
      });
      return total;
    },
  },
  mutations: {
    resetState(state) {
      state.isLoading = false;
    },
    ADD_TO_CART(state, { product, quantity, updated = null }) {
      let productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
      });
      if (productInCart) {
        if (updated) {
          if (quantity > 0) {
            productInCart.quantity = quantity;
          } else {
            state.cart.indexOf(productInCart)
            state.cart.splice(state.cart.indexOf(productInCart), 1);
          }
        } else {
          productInCart.quantity += quantity;
        }
        localStorage.setItem("cart", JSON.stringify(state.cart));
        state.isLoading = false;
        return;
      }
      state.cart.push({
        product,
        quantity,
      });
      state.isLoading = false;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  actions: {
    addProductToCart({ commit, state }, { product, quantity, updated = null }) {
      // commit('resetState')
      state.isLoading = true;
      commit("ADD_TO_CART", { product, quantity, updated });
    },
  },
  modules: {},
};
