<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div class="table-responsive">
      <table
        class="table tablesorter table align-items-center table-flush table-dark"
      >
        <!-- Table Head -->
        <thead class="thead-dark">
          <th>{{ $t("tables.plan") }}</th>
          <th>{{ $t("wallet.amount") }}</th>
          <th>{{ $t("wallet.charge") }}</th>
          <th>{{ $t("tables.orders count") }}</th>
        </thead>
        <tbody>
          <tr>
            <td>Premium</td>
            <td>250 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(250)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 25 {{ $t("wallet.orders") }}</span>
            </td>
          </tr>
          <tr>
            <td>Gold</td>
            <td>200 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(200)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 20 {{ $t("wallet.orders") }}</span>
            </td>
          </tr>
          <tr>
            <td>Silver</td>
            <td>150 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(150)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 15 {{ $t("wallet.orders") }}</span>
            </td>
          </tr>
          <tr>
            <td>Bronze</td>
            <td>100 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(100)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 10 {{ $t("wallet.orders") }}</span>
            </td>
          </tr>
          <tr>
            <td>Normal</td>
            <td>50 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(50)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 5 {{ $t("wallet.orders") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "charge-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      paymentUrl: "",
      traderId: "",
      traderEmail: "",
      traderName: "",
    };
  },
  created() {
    this.traderId = JSON.parse(
      localStorage.getItem("nqalTraderLoginData")
    ).trader_id;
    this.traderEmail = JSON.parse(
      localStorage.getItem("nqalTraderLoginData")
    ).email;
    this.traderName = JSON.parse(
      localStorage.getItem("nqalTraderLoginData")
    ).name;
  },
  methods: {
    chargeWallet: function ($amount) {
      const params = {
        amount: $amount,
        name: this.traderName,
        email: this.traderEmail,
        id: this.traderId,
        type: "trader",
      };
      this.loading = true;
      axios
        .post("wallet/charge", params, {
          headers: {
            "Content-Type": "application/json",
            "x-api-password": "df3djdk322323dsd",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.paymentUrl = response.data.data;
            window.open(this.paymentUrl, "_blank");
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
