<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <router-link :to="`/item-form`" class="btn btn-primary btn-sm"
            ><i class="fas fa-plus"></i> {{ $t("items.new item") }}</router-link
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="items"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.type") }}</th>
          <th>{{ $t("tables.price") }}</th>
          <th>{{ $t("tables.offered price") }}</th>
          <th>{{ $t("tables.qty") }}</th>
          <th>{{ $t("tables.pay type") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img :src="row.item.images[0]" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.title }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.subTraderType }}
          </td>
          <td class="budget">
            {{ row.item.price }}
          </td>
          <td class="budget">
            {{ row.item.offered_price }}
          </td>
          <td class="budget">
            {{ row.item.quantity }}
          </td>
          <td class="budget">
            {{ row.item.pay_type }}
          </td>
          <td class="text-right">
            <router-link
              :to="`/item-form/${row.item.id}`"
              class="btn btn-success btn-sm"
              ><i class="fas fa-edit"></i
            ></router-link>
            <base-button
              type="danger"
              size="sm"
              @click="confirmDelete(row.item)"
            >
              <i class="fas fa-trash"></i>
            </base-button>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "items-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      items: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getItems("trader/items/index?page=1");
  },
  watch: {
    page: function () {
      this.getItems("trader/items/index?page=" + this.page);
    },
  },
  methods: {
    getItems: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.items = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    async confirmDelete(itemData) {
      this.$swal({
        title:
          `${this.$t("validation.are you sure to delete")} ` +
          itemData.title +
          "?",
        text: `${this.$t("validation.you wont be able to revert this")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `${this.$t("global.cancel")}`,
        confirmButtonText: `${this.$t("global.yes delete it")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteItem(itemData);
        }
      });
    },
    async deleteItem(itemData) {
      this.loading = true;
      axios
        .get("trader/items/hideItem/" + itemData.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: `${this.$t("validation.successfully deleted")}`,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.items.splice(this.items.indexOf(itemData), 1);
                this.items_length--;
              }, 600);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    }
    // async deleteItem(languageData) {
    //   this.loading = true;
    //   if (this.checkNetwork(deleteItem)) {
    //     if (deleteItem.is_success) {
    //       this.$swal({
    //         position: "center",
    //         icon: "success",
    //         title: `${this.$t("message.successfully deleted")}`,
    //         showConfirmButton: false,
    //         timer: 1200,
    //       });
    //       setTimeout(() => {
    //         this.items.splice(this.items.indexOf(languageData), 1);
    //         this.items_length--;
    //       }, 600);
    //     } else {
    //       this.$swal({
    //         position: "center",
    //         icon: "error",
    //         title: `${this.$t("message.faild")}`,
    //         text: deleteItem.message,
    //       });
    //     }
    //   }
    //   this.loading = false;
    // },
  },
};
</script>
