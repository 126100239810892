<template>
  <base-loader v-if="loading"></base-loader>
  <div class="row" v-if="deliveryOffers">
    <div
      class="col-xl-4 mb-4"
      v-for="(order, index) in deliveryOffers"
      :key="index"
    >
      <div class="card card-profile shadow order-card">
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span class="badge badge-info">
              {{ $t("global.from") }}
            </span>
          </div>
          <div>
            <h3></h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span class="badge badge-info">
              {{ $t("global.to") }}
            </span>
          </div>
          <div>
            <h3>
              From Lorem ipsum dolor sit, amet consectetur adipisicing elit. A,
              at mollitia minus perspiciatis exercita
            </h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <router-link :to="`/set-price/${order.id}`" class="btn btn-primary">
            {{ $t("global.details") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col-lg-12 alert alert-danger text-center">{{ msg }}</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "active-req-orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      msg: null,
      deliveryOffers: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getActiveReqOrders(
      "trader/traders/orders/activerequestedOrders?page=1"
    );
  },
  watch: {
    page: function () {
      this.getActiveReqOrders(
        "trader/traders/orders/activerequestedOrders?page=" + this.page
      );
    },
  },
  methods: {
    getActiveReqOrders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status) {
              this.deliveryOffers = response.data.data;
              this.page = response.data.meta.current_page;
              this.total_pages = response.data.meta.last_page;
            } else {
              this.msg = response.data.message;
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.order-card h3 span {
  font-size: 14px;
  color: #444;
}
</style>
