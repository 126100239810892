<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <categories-table type="dark" :title="$route.name"></categories-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoriesTable from "../Tables/Categories/CategoriesTable.vue";
export default {
  name: "tables",
  components: {
    CategoriesTable,
  },
};
</script>
<style></style>
