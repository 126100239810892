<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsgLoading">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }} - {{ $t("global.from") }}
            <span class="badge bg-success text-white">{{ from_date }}</span>
            {{ $t("global.to") }}
            <span class="badge bg-success text-white">{{ to_date }}</span>
          </h3>
        </div>
        <div class="col text-right">
          <excel-export
            :sheet_rows="reports"
            :removed_columns="[
              'company_id',
              'sequence',
              'created_at',
              'updated_at',
            ]"
          ></excel-export>
          <router-link
            :to="`/generate-traders-report`"
            class="btn btn-primary btn-sm"
          >
            <i class="fas fa-long-arrow-alt-left"></i>
            {{ $t("global.back") }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="reports"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>{{ $t("reports.sequence") }}</th>
          <th>{{ $t("reports.order id") }}</th>
          <th>{{ $t("reports.order date") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.sequence }}
          </td>
          <td class="budget">
            {{ row.item.order_id }}
          </td>
          <td class="budget">
            {{ row.item.order_date }}
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/orders/${row.item.order_id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/orders/track/${row.item.order_id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-map-marker-alt"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <!-- <base-pagination :pagination="pagination" :paginationLinks="paginationLinks"
      v-on:pagination-prev="getTraders($event)"   
      v-on:pagination-num="getTraders(PaginationNum+$event)" 
      v-on:pagination-next="getTraders($event)"/> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExcelExport from "@/components/ExcelExport";

export default {
  name: "traders-table",
  components: {
    ExcelExport,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },

  data() {
    return {
      renderComponent: false,
      loading: false,
      from_date: "",
      to_date: "",
      trader_id: "",
      reports: "",
    };
  },
  created() {
    if (this.$route.params.dates) {
      this.from_date = this.$route.params.dates[0];
      this.to_date = this.$route.params.dates[1];
      // this.trader_id = this.$route.params.dates[2];
      this.getReport();
    } else {
      this.$router.push({
        name: "generate traders report",
      });
      this.$swal({
        position: "center",
        icon: "error",
        title: `${this.$t("validation.faild")}`,
        text: `${this.$t("validation.all fields required")}`,
      });
    }
  },
  methods: {
    // Add Trader
    getReport: function () {
      this.loading = true;
      let formData = this.toFormData();
      axios
        .post("trader/traders/reports/traderInvoicesGenerate", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.message,
              });
            } else {
              if (response.data.data.length == 0) {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: `${this.$t("validation.faild")}`,
                  text: `${this.$t("validation.no invoices")}`,
                });
              } else {
                this.reports = response.data.data;
              }
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    toFormData: function () {
      var fd = new FormData();
      fd.append("from_date", this.$route.params.dates[0]);
      fd.append("to_date", this.$route.params.dates[1]);
      // fd.append("trader_id", this.$store.getters.traderId);
      return fd;
    },
  },
};
</script>
