<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <SwitchLanguage />
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="get_avatar" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  get_member_name
                }}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{ $t("sidebar.welcome") }}!</h6>
          </div>
          <router-link to="/settings/update-profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>{{ $t("sidebar.update profile") }}</span>
          </router-link>
          <router-link to="/settings/working-hours" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>{{ $t("sidebar.update working hours") }}</span>
          </router-link>
          <router-link to="/settings/menu" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>{{ $t("sidebar.menu settings") }}</span>
          </router-link>
          <router-link to="/trader-menu" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>{{ $t("sidebar.my_menu") }}</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" @click.prevent="logout">
            <i class="ni ni-user-run"></i>
            <span>{{ $t("sidebar.logout") }}</span>
          </a>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import SwitchLanguage from "@/components/SwitchLanguage.vue";
export default {
  components: {
    SwitchLanguage,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    // pending_orders() {
    //   return this.$store.getters.pendingOrders;
    // },
    get_member_name() {
      return this.$store.getters.memberName;
    },
    get_avatar() {
      return this.$store.getters.memberAvatar;
    },
    // get_trader_name() {
    //   return JSON.parse(localStorage.getItem("nqalTraderLoginData")).name;
    // },
    // get_trader_logo() {
    //   return JSON.parse(localStorage.getItem("nqalTraderLoginData"))
    //     .trader_logo;
    // },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("Logout").then((path) => {
        window.location.href = path
        // this.$router.push(path);
      });
    },
  },
};
</script>
