<!-- eslint-disable no-useless-escape -->
<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("orders.new order") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.order type") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <!-- <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.order type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="orderData.order_type"
                      >
                        <option value="" selected>
                          {{ $t("form.order type") }}
                        </option>
                        <option value="send">Send</option>
                        <option value="collect">Collect</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.choose company")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="orderData.company_id"
                      >
                        <option value="" selected>
                          {{ $t("form.choose company") }}
                        </option>
                        <option
                          v-for="(company, index) in companies"
                          :key="index"
                          :value="company.company_id"
                        >
                          {{ company.company_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.sender information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <div id="sender_map" class="map-container" />
                    <div v-if="errorCurLocation">{{ errorCurLocation }}</div>
                  </div>
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.sender_url_addr_map')"
                      :placeholder="$t('form.sender_url_addr_map')"
                      input-classes="form-control-alternative"
                      v-model="pickup_map"
                    />
                    <div v-if="not_valid_map_sender" class="mb-3">
                      <small class="text-danger">{{
                        $t("form.not_valid_map")
                      }}</small>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <base-input
                      alternative=""
                      :label="$t('form.lat')"
                      :placeholder="$t('form.lat')"
                      input-classes="form-control-alternative"
                      v-model="orderData.pickup_lat"
                    />
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <base-input
                      alternative=""
                      :label="$t('form.lng')"
                      :placeholder="$t('form.lng')"
                      input-classes="form-control-alternative"
                      v-model="orderData.pickup_long"
                    />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.lat lng manual")
                      }}</label>
                      <span
                        class="btn btn-primary d-block"
                        @click="
                          getSenderMap(
                            orderData.pickup_lat,
                            orderData.pickup_long
                          )
                        "
                        >{{ $t("form.get location") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.address from')"
                      :placeholder="$t('form.address from')"
                      input-classes="form-control-alternative"
                      v-model="orderData.address"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.receiver information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.receiver name')"
                      :placeholder="$t('form.receiver name')"
                      input-classes="form-control-alternative"
                      v-model="orderData.receiver_name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.receiver phone')"
                      :placeholder="$t('form.receiver phone')"
                      input-classes="form-control-alternative"
                      v-model="orderData.receiver_phone"
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <div id="receiver_map" class="map-container" />
                    <div v-if="errorCurLocation">{{ errorCurLocation }}</div>
                  </div>
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.receiver_url_addr_map')"
                      :placeholder="$t('form.sender_url_addr_map')"
                      input-classes="form-control-alternative"
                      v-model="receiver_map"
                    />
                    <div v-if="not_valid_map_receiver" class="mb-3">
                      <small class="text-danger">{{
                        $t("form.not_valid_map")
                      }}</small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <base-input
                      alternative=""
                      :label="$t('form.lat')"
                      :placeholder="$t('form.lat')"
                      input-classes="form-control-alternative"
                      v-model="orderData.receiver_lat"
                    />
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <base-input
                      alternative=""
                      :label="$t('form.lng')"
                      :placeholder="$t('form.lng')"
                      input-classes="form-control-alternative"
                      v-model="orderData.receiver_long"
                    />
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.lat lng manual")
                      }}</label>
                      <span
                        class="btn btn-primary d-block"
                        @click="
                          getReceiverMap(
                            orderData.receiver_lat,
                            orderData.receiver_long
                          )
                        "
                        >{{ $t("form.get location") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.receiver address')"
                      :placeholder="$t('form.receiver address')"
                      input-classes="form-control-alternative"
                      v-model="orderData.receiver_address"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.state')"
                      :placeholder="$t('form.state')"
                      input-classes="form-control-alternative"
                      v-model="orderData.state"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.item information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.choose_from_items")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="selected_item"
                        @change="getItemData(selected_item)"
                      >
                        <option value="" selected>
                          {{ $t("form.choose_from_items") }}
                        </option>
                        <option
                          v-for="(item, index) in items"
                          :key="index"
                          :value="item"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.item_title')"
                      :placeholder="$t('form.item_title')"
                      input-classes="form-control-alternative"
                      v-model="orderData.title"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.item_value')"
                      :placeholder="$t('form.item_value')"
                      input-classes="form-control-alternative"
                      v-model="orderData.value"
                    />
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.item_description")
                      }}</label>
                      <textarea
                        class="form-select form-control form-control-alternative"
                        :placeholder="$t('form.item_description')"
                        v-model="orderData.description"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.pack size')"
                      :placeholder="$t('form.pack size')"
                      input-classes="form-control-alternative"
                      v-model="orderData.pack_size"
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label d-block">
                        {{ $t("form.paid status") }}
                      </label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="item_paid"
                          false-value="2"
                          true-value="1"
                          v-model="orderData.items_paid"
                        />
                        <label class="form-check-label" for="item_paid">
                          {{ $t("form.already paid") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="orderData.items_paid == 2">
                    <base-input
                      alternative=""
                      :label="$t('form.price')"
                      :placeholder="$t('form.price')"
                      input-classes="form-control-alternative"
                      v-model="orderData.item_price"
                    />
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label d-block">
                        {{ $t("form.delivery date") }}
                      </label>
                      <div class="form-check form-check-inline me-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="delivery_date"
                          true-value="1"
                          false-value="0"
                          v-model="orderData.now"
                          @change="resetDeliveryDate('now')"
                        />
                        <label class="form-check-label" for="delivery_date">
                          {{ $t("form.now") }}
                        </label>
                      </div>
                      <div class="form-check form-check-inline me-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="delivery_date_24"
                          true-value="1"
                          false-value="0"
                          v-model="orderData.twenty_four_hour"
                          @change="resetDeliveryDate('24')"
                        />
                        <label class="form-check-label" for="delivery_date_24">
                          {{ $t("form.through_24_hours") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-12"
                    v-if="orderData.now == 0 && orderData.twenty_four_hour == 0"
                  >
                    <base-input
                      alternative=""
                      type="date"
                      :label="$t('form.delivery date')"
                      :placeholder="$t('form.delivery date')"
                      input-classes="form-control-alternative"
                      v-model="orderData.delivery_date"
                    />
                    <label class="form-check-label me-3" for="delivery_date_24">
                      {{ $t("form.delivery_time") }}:
                    </label>
                    <div
                      class="form-check form-check-inline me-3"
                      v-for="(time_slot, index) in time_slots"
                      :key="index"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :id="'time' + time_slot.id"
                        :value="time_slot.id"
                        v-model="orderData.time_slot_id"
                      />
                      <label
                        class="form-check-label ltr"
                        :for="'time' + time_slot.id"
                      >
                        {{ time_slot.from_time + " - " + time_slot.to_time }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.additional information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.choose shipping type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="orderData.shipping_types_id"
                      >
                        <option value="" selected>
                          {{ $t("form.choose shipping type") }}
                        </option>
                        <option
                          v-for="(shippingType, index) in shippingTypes"
                          :key="index"
                          :value="shippingType.id"
                        >
                          {{ shippingType.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.choose vechile type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="orderData.vehicle_type_id"
                      >
                        <option value="" selected>
                          {{ $t("form.choose vechile type") }}
                        </option>
                        <option
                          v-for="(vechileType, index) in vechileTypes"
                          :key="index"
                          :value="vechileType.id"
                        >
                          {{ vechileType.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.choose payment method")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="orderData.payment_methods_id"
                      >
                        <option value="" selected>
                          {{ $t("form.choose payment method") }}
                        </option>
                        <option
                          v-for="(paymentMethod, index) in paymentMethods"
                          :key="index"
                          :value="paymentMethod.id"
                        >
                          {{ paymentMethod.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <!-- <button type="submit" class="btn btn-success d-block">
                {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
              </button> -->
              <button type="submit" class="btn btn-primary">
                {{
                  this.$route.params.id
                    ? $t("form.save")
                    : $t("form.add_to_my_company")
                }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Loader } from "google-maps";
const loader = new Loader("AIzaSyDx-Roz0uBFP0w6YV2RMhYEwurSAVIH3IQ");
export default {
  name: "new-order",
  data() {
    return {
      loading: false,
      validationErrors: [],
      not_valid_map_sender: false,
      not_valid_map_receiver: false,
      shippingTypes: [],
      vechileTypes: [],
      paymentMethods: [],
      companies: [],
      items: [],
      selected_item: "",
      time_slots: [],
      pickup_map: "",
      receiver_map: "",
      orderData: {
        title: "",
        description: "",
        value: "",
        shipping_types_id: "",
        vehicle_type_id: "",
        payment_methods_id: "",
        receiver_lat: "",
        receiver_long: "",
        receiver_name: "",
        receiver_phone: "",
        receiver_address: "",
        address: "",
        state: "",
        image: "images/requests/items/1640819327.jpg",
        order_requests_id: "",
        items_paid: 2,
        item_price: 0,
        pack_size: "",
        price: 0,
        now: 0,
        twenty_four_hour: 0,
        time_slot_id: "",
        delivery_date: "",
        order_type: "send",
        company_id: "",
        pickup_lat: "",
        pickup_long: "",
      },
      location: null,
      gettingLocation: false,
      errorCurLocation: null,
    };
  },
  watch: {
    pickup_map: function (url) {
      if (!url) {
        this.not_valid_map_sender = false;
      } else {
        this.not_valid_map_sender = false;
        let regex = new RegExp("@(.*),(.*),");
        let lat_long_match = url.match(regex);
        if (!lat_long_match) {
          regex = new RegExp("=(.*),(.*)");
          lat_long_match = url.match(regex);
        }
        if (!lat_long_match) {
          regex = new RegExp("=(.*)8%2C(.*)&z");
          lat_long_match = url.match(regex);
        }
        if (lat_long_match) {
          let lat = lat_long_match[1];
          let long = lat_long_match[2];
          this.orderData.pickup_lat = lat;
          this.orderData.pickup_long = long;
          this.getLocationAddress(lat, long, "address_from");
        } else {
          this.not_valid_map_sender = true;
        }
      }
    },
    receiver_map: function (url) {
      if (!url) {
        this.not_valid_map_receiver = false;
      } else {
        this.not_valid_map_receiver = false;
        let regex = new RegExp("@(.*),(.*),");
        let lat_long_match = url.match(regex);
        if (!lat_long_match) {
          regex = new RegExp("=(.*),(.*)");
          lat_long_match = url.match(regex);
        }
        if (!lat_long_match) {
          regex = new RegExp("=(.*)8%2C(.*)&z");
          lat_long_match = url.match(regex);
        }
        if (lat_long_match) {
          let lat = lat_long_match[1];
          let long = lat_long_match[2];
          this.orderData.receiver_lat = lat;
          this.orderData.receiver_long = long;
          this.getLocationAddress(lat, long, "address_to");
        } else {
          this.not_valid_map_receiver = true;
        }
      }
    },
  },
  created() {
    this.getShippingTypes();
    this.getVechileTypes();
    this.getPaymentMethods();
    this.getItems("trader/items/all");
    this.getCompanies("trader/traders/linkedcompanies");
    this.getTimeSlot("general/timeSlots");
  },
  mounted() {
    this.getCurrentLocation();
  },
  methods: {
    // Start Maps
    // Get Cureent Location
    getCurrentLocation: function () {
      // do we support geolocation
      if (!("geolocation" in navigator)) {
        this.errorCurLocation = "Geolocation is not available.";
        return;
      }
      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.orderData.pickup_lat = this.location.coords.latitude;
          this.orderData.pickup_long = this.location.coords.longitude;
          this.orderData.receiver_lat = this.location.coords.latitude;
          this.orderData.receiver_long = this.location.coords.longitude;
          this.getSenderMap(
            this.location.coords.latitude,
            this.location.coords.longitude
          );
          this.getReceiverMap(
            this.location.coords.latitude,
            this.location.coords.longitude
          );
        },
        (err) => {
          this.gettingLocation = false;
          this.errorCurLocation = err.message;
        }
      );
    },
    getSenderMap: function (x, y) {
      var thisData = this;
      loader.load().then(function (google) {
        var center = new google.maps.LatLng(x, y); // Center
        const mapOptions = {
          zoom: 13,
          center: center,
          mapId: "2bf1cba222371325",
          scrollwheel: false,
          disableDefaultUI: true,
          zoomControl: true,
        };
        var map = new google.maps.Map(
          document.getElementById("sender_map"),
          mapOptions
        );
        var marker = new google.maps.Marker({
          position: center,
          draggable: true,
          map,
          title: "From Location",
        });
        // Zoom to 9 when clicking on marker
        google.maps.event.addListener(marker, "click", function () {
          map.setZoom(9);
          map.setCenter(marker.getPosition());
        });
        google.maps.event.addListener(marker, "dragend", function () {
          thisData.orderData.pickup_lat = marker.position.lat();
          thisData.orderData.pickup_long = marker.position.lng();
        });
        // click on map and set you marker to that position
        google.maps.event.addListener(map, "click", function (event) {
          marker.setPosition(event.latLng);
          thisData.orderData.pickup_lat = marker.position.lat();
          thisData.orderData.pickup_long = marker.position.lng();
        });
      });
    },
    getReceiverMap: function (x, y) {
      var thisData = this;
      loader.load().then(function (google) {
        var center = new google.maps.LatLng(x, y); // Center
        const mapOptions = {
          zoom: 13,
          center: center,
          mapId: "2bf1cba222371325",
          scrollwheel: false,
          disableDefaultUI: true,
          zoomControl: true,
        };
        var map = new google.maps.Map(
          document.getElementById("receiver_map"),
          mapOptions
        );
        var marker = new google.maps.Marker({
          position: center,
          draggable: true,
          map,
          title: "To Location",
        });
        // Zoom to 9 when clicking on marker
        google.maps.event.addListener(marker, "click", function () {
          map.setZoom(9);
          map.setCenter(marker.getPosition());
        });
        google.maps.event.addListener(marker, "dragend", function () {
          thisData.orderData.receiver_lat = marker.position.lat();
          thisData.orderData.receiver_long = marker.position.lng();
        });
        // click on map and set you marker to that position
        google.maps.event.addListener(map, "click", function (event) {
          marker.setPosition(event.latLng);
          thisData.orderData.receiver_lat = marker.position.lat();
          thisData.orderData.receiver_long = marker.position.lng();
        });
      });
    },
    // End Maps
    validation: function () {
      this.validationErrors = [];
      if (this.orderData.twenty_four_hour) {
        console.log("TWENTY");
      }
      if (this.orderData.now) {
        console.log("NOW");
      }
      if (
        this.orderData.title &&
        this.orderData.description &&
        this.orderData.value &&
        this.orderData.shipping_types_id &&
        this.orderData.vehicle_type_id &&
        this.orderData.payment_methods_id &&
        this.orderData.receiver_name &&
        this.orderData.receiver_phone &&
        this.orderData.receiver_address &&
        this.orderData.address &&
        this.orderData.state &&
        this.orderData.image &&
        (this.orderData.items_paid == 1 || this.orderData.item_price) &&
        this.orderData.pack_size &&
        (this.orderData.now != 0 ||
          this.orderData.twenty_four_hour != 0 ||
          (this.orderData.time_slot_id && this.orderData.delivery_date)) &&
        this.orderData.order_type &&
        this.orderData.pickup_lat &&
        this.orderData.pickup_long &&
        this.orderData.receiver_lat &&
        this.orderData.receiver_long
      ) {
        return true;
      } else {
        this.validationErrors.push(
          `${this.$t("validation.all fields required")}`
        );
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        // this.itemData._method = "put";
        // let formData = this.toFormData(this.itemData);
        // this.updateItem(formData);
      } else {
        let formData = this.toFormData(this.orderData);
        this.newOrder(formData);
      }
    },
    // Request New Deal
    newOrder: function (formData) {
      this.loading = true;
      axios
        .post("trader/traders/orders/placeordercompany", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            console.log(response.data.status);
            if (response.data.status == false) {
              this.validationErrors.push(response.data.msg);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.msg,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/dashboard");
              }, 1500);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Shipping Types
    getShippingTypes: function () {
      this.loading = true;
      axios
        .get("trader/common/shippingTypes")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.shippingTypes = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Vechile Types
    getVechileTypes: function () {
      this.loading = true;
      axios
        .get("trader/common/vehicleTypes")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.vechileTypes = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Payment MMethod Types
    getPaymentMethods: function () {
      this.loading = true;
      axios
        .get("trader/common/paymentMethods")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.paymentMethods = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
    getCompanies: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.companies = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    getItems: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.items = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    getTimeSlot: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.time_slots = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    resetDeliveryDate: function (type) {
      if (event.target._modelValue == 1) {
        this.orderData.time_slot_id = "";
        this.orderData.delivery_date = "";
        if (type == "now") {
          this.orderData.twenty_four_hour = 0;
        } else if (type == "24") {
          this.orderData.now = 0;
        }
      }
    },
    async getLocationAddress(lat, long, type) {
      let that = this;
      let addressType = type;
      let x;
      await loader.load().then(function (google) {
        const geocoder = new google.maps.Geocoder();
        const latlng = {
          lat: parseFloat(lat),
          lng: parseFloat(long),
        };
        geocoder.geocode({ location: latlng }).then((response) => {
          if (response.results[0]) {
            if (addressType == "address_from") {
              that.orderData.address = response.results[0].formatted_address;
            } else if (addressType == "address_to") {
              that.orderData.receiver_address =
                response.results[0].formatted_address;
            }
          } else {
            window.alert("No results found");
          }
        });
        return x;
      });
    },
    getItemData: function (item) {
      let userLang = localStorage.getItem("userLang") ?? "ar";
      this.orderData.value = item.price;
      this.orderData.pack_size = item.size;
      if(userLang == "ar") {
        this.orderData.title = item.local_title;
        this.orderData.description = item.local_content;
      } else {
        this.orderData.title = item.title;
        this.orderData.description = item.content;
      }
    },
  },
};
</script>
<style>
.map-container {
  height: 400px;
}
.me-3 {
  margin-inline-end: 1rem;
}
.ltr {
  direction: ltr;
}
</style>
