<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="users"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("form.role") }}</th>
          <th>{{ $t("tables.action") }}</th>
          <th>{{ $t("tables.day from") }}</th>
          <th>{{ $t("tables.day to") }}</th>
          <th>{{ $t("tables.time from") }}</th>
          <th>{{ $t("tables.time to") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img :alt="row.item.name + 'Image'" :src="row.item.avatar" />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget">
            {{ row.item.phone }}
          </td>
          <td class="budget">
            {{ row.item.role }}
          </td>
          <td
            class="budget text-right"
            v-if="row.item.role == 'trader_super_admin'"
          ></td>
          <td
            class="budget text-right"
            v-if="row.item.role && row.item.role != 'trader_super_admin'"
          >
            <select
              class="btn btn-success btn-sm text-left"
              @change="updateAssignRole(row.item.id, $event)"
            >
              <option value="">{{ $t("form.choose role") }}</option>
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.name"
              >
                {{ role.name }}
              </option>
            </select>
          </td>
          <td class="budget text-right" v-if="!row.item.role">
            <select
              class="btn btn-success btn-sm text-left"
              @change="assignRole(row.item.id, $event)"
            >
              <option value="">{{ $t("form.choose role") }}</option>
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.name"
              >
                {{ role.name }}
              </option>
            </select>
          </td>
          <td class="budget">
            {{ row.item.day_from }}
          </td>
          <td class="budget">
            {{ row.item.day_to }}
          </td>
          <td class="budget">
            {{ row.item.time_from }}
          </td>
          <td class="budget">
            {{ row.item.time_to }}
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "users-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      users: "",
      roles: "",
      assigndRole: {
        member_id: "",
        role_name: "",
      },
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getUsers("trader/traders/members/index?page=1");
    this.getRoles("trader/traders/members/roles/index");
  },
  watch: {
    page: function () {
      this.getUsers("trader/traders/members/index?page=" + this.page);
    },
  },
  methods: {
    getUsers: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get All Roles
    getRoles: function ($url) {
      this.loading = true;
      axios
        .get($url)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.roles = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Assign Role
    assignRole: function ($user_id) {
      this.loading = true;
      this.assigndRole.member_id = $user_id;
      this.assigndRole.role_name = event.target.value;
      axios
        .post("trader/traders/members/assignRole", this.assigndRole)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              this.users.filter((user) => {
                if (user.id === this.assigndRole.member_id) {
                  user.role = this.assigndRole.role_name;
                }
              });
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update User Role
    updateAssignRole: function ($user_id) {
      this.loading = true;
      this.assigndRole.member_id = $user_id;
      this.assigndRole.role_name = event.target.value;
      this.assigndRole._method = "put";
      axios
        .post("trader/traders/members/updateAssignRole", this.assigndRole)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.users.filter((user) => {
                if (user.id === this.assigndRole.member_id) {
                  user.role = this.assigndRole.role_name;
                }
              });
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
